import {
  Box,
  Button,
  Drawer,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

interface IProps {
  open: boolean;
  handleClose: any;
  reference: string;
  setReference: Function;
  orderRef: string;
  setOrderRef: Function;
  type: string;
  setType: Function;
}

const FilterDrawer = (props: IProps) => {
  const { t } = useTranslation("matching");
  const { t: generalT } = useTranslation("general");
  const {
    open,
    handleClose,
    reference,
    setReference,
    type,
    setType,
    orderRef,
    setOrderRef,
  } = props;
  const [tempRef, setTempRef] = useState(reference);
  const [tempOrderRef, setTempOrderRef] = useState(orderRef);
  const [tempType, setTempType] = useState(type);

  const handleApply = () => {
    handleClose();
    setReference(tempRef);
    setOrderRef(tempOrderRef);
    setType(tempType);
  };

  const handleClearFilters = () => {
    handleClose();
    setReference("");
    setOrderRef("");
    setType("");

    setTempRef("");
    setTempOrderRef("");
    setTempType("all");
  };

  const Types = [
    {
      label: generalT("all"),
      value: "all",
    },
    {
      label: t("matched"),
      value: "matched",
    },
    {
      label: t("unmatched"),
      value: "unmatched",
    },
  ];

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Stack
        maxWidth={{ xs: "100%", sm: "376px" }}
        minWidth={{ xs: "100%", sm: "376px" }}
        borderRight={"1px solid #D0D5DD"}
        height={"100%"}
        maxHeight={"100vh"}
        top={"0"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
        >
          <Button
            color="tertiary"
            variant="text"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {t("filter")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClose}
          >
            {t("hide")}
          </Button>
        </Box>
        <Stack spacing={"20px"} p={"40px 27px"}>
          {/* reference */}
          <TextField
            label={t("reference")}
            variant="outlined"
            size="small"
            fullWidth
            value={tempRef}
            onChange={(e) => setTempRef(e.target.value)}
          />
          {/* order reference */}
          <TextField
            label={t("orderReference")}
            variant="outlined"
            size="small"
            fullWidth
            value={tempOrderRef}
            onChange={(e) => setTempOrderRef(e.target.value)}
          />
          {/*  type */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("type")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={tempType}
              onChange={(e) => setTempType(e.target.value)}
              input={<OutlinedInput label={t("type")} />}
            >
              {Types.map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* divider */}
          <Box width={"100%"} height={"1px"} bgcolor={"#CBD5E1"} />
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
          alignItems={"flex-end"}
          mt="auto"
        >
          <Button variant="contained" size="small" onClick={handleApply}>
            {generalT("apply")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClearFilters}
          >
            {generalT("clear")}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default FilterDrawer;
