import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import TransactionsTable from "./table";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { useEffect, useState } from "react";
import FilterDrawer from "./filters";
import {
  useDownloadAggregatorsTransList,
  useGetAggTransactionsList,
} from "src/api/aggreagators";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";
import { useTranslation } from "react-i18next";
import { downloadFile } from "src/utils";
import { LoadingButton } from "@mui/lab";

const Transactions = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const [payments, setPayments] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [branches, setBranches] = useState<number[]>([]);
  const [aggregators, setAggregators] = useState<number[]>([]);
  const [refNum, setRefNum] = useState("");
  const [hasAggregatorDeliveryCost, setHasAggregatorDeliveryCost] =
    useState("");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("aggregators");

  const { data, refetch, isFetching } = useGetAggTransactionsList({
    start_date: dateRange.startDate || undefined,
    end_date: dateRange.endDate || undefined,
    ...(payments.length > 0 && { payment_methods: payments }),
    ...(branches.length > 0 && { branches: branches }),
    ...(aggregators.length > 0 && { aggregators: aggregators }),
    ...(!!refNum && { reference_number: refNum }),
    ...(hasAggregatorDeliveryCost !== "" && {
      has_aggregator_delivery: Number(hasAggregatorDeliveryCost),
    }),
    page: pageNumber + 1,
  });

  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status,
  } = useDownloadAggregatorsTransList({
    start_date: dateRange.startDate || undefined,
    end_date: dateRange.endDate || undefined,
    ...(payments.length > 0 && { payment_methods: payments }),
    ...(branches.length > 0 && { branches: branches }),
    ...(aggregators.length > 0 && { aggregators: aggregators }),
    ...(!!refNum && { reference_number: refNum }),
    ...(hasAggregatorDeliveryCost !== "" && {
      has_aggregator_delivery: Number(hasAggregatorDeliveryCost),
    }),
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleDownload = () => {
    refetchDownload();
  };

  useEffect(() => {
    if (
      !!dateRange.endDate ||
      (dateRange.endDate === undefined && dateRange.startDate === undefined)
    ) {
      refetch();
    }
  }, [dateRange.endDate]);

  useEffect(() => {
    if (!open) {
      refetch();
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setTotal(data.meta.total);
    }
  }, [data]);

  useEffect(() => {
    if (status === "success" && !!downloadedData) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", "Aggregators-Transactions");
    }
  }, [isFetchingDownload]);

  return (
    <Stack spacing={3}>
      <Stack
        sx={{ flexDirection: { xs: "column", lg: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography fontWeight={600} lineHeight={"19.2px"} color={"#475467"}>
          {t("recentTransactions")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {/*  Date range Select  */}
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              } // moment.Moment | null
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null} // moment.Moment | null
              endDateId={`your_unique_end_date_id`}
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              showClearDates
            />
          </Box>

          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
            onClick={handleOpen}
          >
            {t("filter")}
          </Button>
          <LoadingButton
            sx={{
              height: "40px",
              borderRadius: "4px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
            loading={isFetchingDownload}
            onClick={handleDownload}
          >
            {t("download")}
          </LoadingButton>
        </Stack>
        <FilterDrawer
          open={open}
          payments={payments}
          setPayments={setPayments}
          setBranches={setBranches}
          branches={branches}
          aggregators={aggregators}
          setAggregators={setAggregators}
          refNum={refNum}
          setRefNum={setRefNum}
          hasAggregatorDeliveryCost={hasAggregatorDeliveryCost}
          setHasAggregatorDeliveryCost={setHasAggregatorDeliveryCost}
          handleClose={handleClose}
        />
      </Stack>
      <TransactionsTable data={data?.data} isFetching={isFetching} />

      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default Transactions;
