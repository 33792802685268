import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetBranches, useGetPaymentMethods } from "src/api/generic";
import { useGetOCAccountsList } from "src/api/operation-cost/accounts";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import { SettingsPermissions } from "src/constants/permissions";
import { specificBranchHasPermission } from "src/utils";

interface IProps {
  open: boolean;
  handleClose: any;
  branches: number[];
  setBranches: Function;
  payments: number[];
  setPayments: Function;
  refNum: string;
  setRefNum: Function;
  supplierId: string;
  setSupplierId: Function;
  accountId: string;
  setAccountId: Function;
  type: string;
  setType: Function;
}

const FilterDrawer = (props: IProps) => {
  const {
    open,
    handleClose,
    branches,
    setBranches,
    payments,
    setPayments,
    refNum,
    setRefNum,
    supplierId,
    setSupplierId,
    accountId,
    setAccountId,
    type,
    setType,
  } = props;

  // APIS
  const { data: branchesData } = useGetBranches();
  const { data: paymentsData } = useGetPaymentMethods();
  const { data: accountsData } = useGetOCAccountsList();
  const { data: suppliersData } = useGetSuppliersList();
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  // permissions
  const branchesHavePermission =
    branchesData?.data.filter((branch) =>
      specificBranchHasPermission(
        SettingsPermissions.viewPaymentTransactions,
        branch.id
      )
    ) || [];

  const handleChangeBranch = (event: any) => {
    const {
      target: { value },
    } = event;
    setBranches(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangePayments = (event: any) => {
    const {
      target: { value },
    } = event;
    setPayments(typeof value === "string" ? value.split(",") : value);
  };

  const handleClearFilters = () => {
    setBranches([]);
    setPayments([]);
    setRefNum("");
    setSupplierId("");
    setAccountId("");
    setType("all");
    handleClose();
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleClose}>
      <Stack
        maxWidth={{ xs: "100%", sm: "376px" }}
        minWidth={{ xs: "100%", sm: "376px" }}
        borderRight={"1px solid #D0D5DD"}
        height={"100%"}
        maxHeight={"100vh"}
        top={"0"}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
        >
          <Button
            color="tertiary"
            variant="text"
            size="small"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {generalT("filter")}
          </Button>

          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClose}
          >
            {generalT("hide")}
          </Button>
        </Box>
        <Stack spacing={"20px"} p={"40px 27px"}>
          <TextField
            label={generalT("referenceNumber")}
            variant="outlined"
            size="small"
            fullWidth
            value={refNum}
            onChange={(e) => setRefNum(e.target.value)}
          />

          {/*  Branch Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {generalT("branch")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={branches}
              onChange={handleChangeBranch}
              input={<OutlinedInput label={generalT("branch")} />}
              renderValue={(selected) =>
                selected
                  .map(
                    (selectedId: number) =>
                      branchesHavePermission.find(
                        (branch: any) => branch.id === +selectedId
                      )?.name
                  )
                  .join(", ")
              }
            >
              {branchesHavePermission.map((branch) => (
                <MenuItem
                  key={branch.id}
                  value={branch.id}
                  sx={{ padding: "0" }}
                >
                  <Checkbox
                    size="small"
                    checked={branches.indexOf(branch.id) > -1}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* payments select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {generalT("payments")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={payments}
              onChange={handleChangePayments}
              input={<OutlinedInput label={generalT("payments")} />}
              renderValue={(selected) =>
                selected
                  .map(
                    (selectedId: number) =>
                      paymentsData?.data.find(
                        (branch: any) => branch.id === +selectedId
                      )?.name
                  )
                  .join(", ")
              }
            >
              {paymentsData?.data.map((branch) => (
                <MenuItem
                  key={branch.id}
                  value={branch.id}
                  sx={{ padding: "0" }}
                >
                  <Checkbox
                    size="small"
                    checked={payments?.indexOf(branch.id) > -1}
                  />
                  <ListItemText primary={branch.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/*  Supplier Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("supplier")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={supplierId}
              onChange={(e) => setSupplierId(e.target.value)}
              input={<OutlinedInput label={generalT("supplier")} />}
            >
              {suppliersData?.data?.map((sup: any) => (
                <MenuItem key={sup.id} value={sup.id}>
                  <ListItemText primary={sup.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/*  Account Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("account")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={accountId}
              onChange={(e) => setAccountId(e.target.value)}
              input={<OutlinedInput label={generalT("supplier")} />}
            >
              {accountsData?.data?.map((account: any) => (
                <MenuItem key={account.id} value={account.id}>
                  <ListItemText primary={account.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/*  type Select */}
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-checkbox-label">
              {t("type")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              value={type}
              onChange={(e) => setType(e.target.value)}
              input={<OutlinedInput label={t("type")} />}
            >
              <MenuItem value={"all"}>
                <ListItemText primary={generalT("all")} />
              </MenuItem>
              <MenuItem value={"foodics"}>
                <ListItemText primary={generalT("foodics")} />
              </MenuItem>
              <MenuItem value={"manual"}>
                <ListItemText primary={generalT("manual")} />
              </MenuItem>
            </Select>
          </FormControl>

          {/* divider */}
          <Box width={"100%"} height={"1px"} bgcolor={"#CBD5E1"} />
        </Stack>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          p={"20px 27px"}
          bgcolor={"#fff"}
          boxShadow="0px 0px 40px 0px rgba(11, 24, 42, 0.07)"
          alignItems={"flex-end"}
          mt="auto"
        >
          <Button variant="contained" size="small" onClick={handleClose}>
            {generalT("apply")}
          </Button>
          <Button
            variant="outlined"
            color="tertiary"
            size="small"
            onClick={handleClearFilters}
          >
            {generalT("clear")}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};
export default FilterDrawer;
