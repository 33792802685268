import { Box, IconButton, Link, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";
import FileUploader from "src/shared/components/file-uploader";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useImportItemsMutation } from "src/api/purchasing/items";
import { downloadCsvTemplate } from "src/utils";

interface IModel {
  open: boolean;
  handleClose: () => void;
  refetch?: Function;
}

const schema = yup.object().shape({
  uploaded_file: yup.string().required("required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const ImportItemsPopup = (props: IModel) => {
  const { open, handleClose, refetch = () => {} } = props;
  const { t } = useTranslation("inventory");
  const { t: addSalesT } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [uploadedFile, setUploadedFile] = useState<any>();
  const [fileData, setFileData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // APIS
  const { mutate, status, error } = useImportItemsMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const handleDownloadTemplate = () => {
    const columns = ["name", "sku", "unit", "type", "shelf_life"];
    downloadCsvTemplate(columns, "items_template.csv");
  };

  const handleFileUpload = (data: any) => {
    console.log({ data });
    setUploadedFile(data || undefined);
    if (data) {
      Papa.parse(data, {
        header: true,
        complete: (results: any) => {
          console.log({ results });
          setFileData(results.data);
        },
        skipEmptyLines: true,
      });
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    //no file
    if (!uploadedFile) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: addSalesT("uploadFile"),
        },
      ]);
      return;
    }
    //empty file
    if (fileData.length === 0) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: addSalesT("emptyFile"),
        },
      ]);
      return;
    }

    const formDataToSend = new FormData();

    formDataToSend.append("file", uploadedFile);

    setIsLoading(true);
    mutate(formDataToSend);
  };

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      const delayedExecution = setTimeout(() => {
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: generalT("addedSuccessfully"),
          },
        ]);
        setIsLoading(false);
        handleClose();
        refetch();
      }, 5000);

      return () => clearTimeout(delayedExecution);
    } else if (status === "error") {
      setIsLoading(false);
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("importItems")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={1.5}>
            <Typography mb={1}>
              {addSalesT("toUploadYourSalesFromExcel")}
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.8}>
              <Box
                bgcolor={"#000"}
                width={"4px"}
                height={"4px"}
                borderRadius={"50%"}
              />
              <Typography>{addSalesT("downloadOur")}</Typography>
              <Link
                color={"#1A1AE6"}
                sx={{ cursor: "pointer" }}
                onClick={handleDownloadTemplate}
              >
                {addSalesT("template")}.
              </Link>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={0.8}>
              <Box
                bgcolor={"#000"}
                width={"4px"}
                height={"4px"}
                borderRadius={"50%"}
              />
              <Typography>{addSalesT("fulfillRequiredColumns")}</Typography>
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={0.8}>
              <Box
                bgcolor={"#000"}
                width={"4px"}
                height={"4px"}
                borderRadius={"50%"}
              />
              <Typography>{addSalesT("uploadUpdatedFileHere")}</Typography>
            </Stack>
          </Box>

          <FileUploader
            label={""}
            onFileUpload={(data) => handleFileUpload(data)}
            onFileDelete={() => {
              setUploadedFile(undefined);
              setValue("uploaded_file", "");
            }}
            id="uploaded_file"
            name="uploaded_file"
            control={control}
            error={!!errors.uploaded_file}
            helperText={errors.uploaded_file?.message}
            isLoading={false}
            value={uploadedFile}
          />

          <Stack mt={3} spacing={2} direction={"row"}>
            <LoadingButton
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
              disabled={isLoading}
            >
              {generalT("cancel")}
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              type="submit"
              variant="contained"
              fullWidth
            >
              {generalT("submit")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default ImportItemsPopup;
