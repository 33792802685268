import { useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import {
  useAddCapitalContributorMutation,
  useUpdateCapitalContributorMutation,
} from "src/api/settings/capital";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  amount: yup.string().required("Required"),
  note: yup.string(),
  date: yup.date().required("Required"),
});

const initForm = {
  name: "",
  amount: "",
  // date: undefined,
  note: "",
};

interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetch: Function;
  capitalData?: any;
}

const AddEditContributorPopup = (props: IProps) => {
  const { open, handleClose, refetch, capitalData } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("settings");
  const { t: generalT } = useTranslation("general");

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: initForm,
  });

  const mode = !capitalData ? "add" : "edit";

  // APIS
  const { mutate, isPending, status, error } =
    useAddCapitalContributorMutation();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateCapitalContributorMutation();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const dataToSend = {
      name: data.name,
      amount: data.amount,
      date: moment(data.date).format("YYYY-MM-DD"),
      ...(!!data.note && { note: data.note }),
    };
    if (mode === "add") {
      mutate(dataToSend);
    } else {
      mutateUpdate({
        id: capitalData.id,
        ...dataToSend,
      });
    }
  };

  useEffect(() => {
    if (capitalData) {
      reset({
        name: capitalData.name,
        amount: capitalData.amount,
        date: capitalData.date
          ? moment(capitalData.date, "YYYY-MM-DD").toDate()
          : undefined,
        note: capitalData.note || "",
      });
    }
  }, [capitalData]);

  //Add contributor success and error handling
  useEffect(() => {
    if (status === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update contributor success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addContributor") : t("editContributor")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("name")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            {/* amount */}
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("amount")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.amount}
                  helperText={errors.amount?.message}
                />
              )}
            />

            {/* Received date  */}
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!errors.date,
                      helperText: t(`${errors.date?.message ?? ""}`),
                      size: "small",
                    },
                  }}
                  format={"dd/MM/yyyy"}
                  label={t("receivedDate")}
                />
              )}
            />

            {/* note */}
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("note")}
                  fullWidth
                  size="small"
                  multiline
                  rows={4}
                  maxRows={4}
                  {...field}
                  error={!!errors.note}
                  helperText={errors.note?.message}
                />
              )}
            />
          </Stack>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("addContributor") : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditContributorPopup;
