import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import handshakeIcon from "src/assets/svg-icons/aggregators/handshake.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useAddAggMutation,
  useGetAggregatorById,
  useUpdateAggMutation,
} from "src/api/aggreagators";
import { useGetPaymentMethods, useGetBranches } from "src/api/generic";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { specificBranchHasPermission } from "src/utils";

interface FormInputs {
  branch_id: number;
  name: string;
  aggregator_fee: number;
  payment_methods: number[];
}

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  name: yup.string().required("Required"),
  aggregator_fee: yup.number().required("Required"),
  payment_methods: yup
    .array()
    .of(yup.number().required("Required"))
    .required("Required")
    .min(1, "Required"),
});

const AddNewAggregator = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("aggregators");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const mode: "add" | "edit" = params?.id ? "edit" : "add";

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  // APIS
  const { data: branchesData } = useGetBranches();
  const { data: paymentsData } = useGetPaymentMethods({
    enabled: true,
  });
  const { mutate, status, error, isPending } = useAddAggMutation();
  const {
    mutate: mutateUpdate,
    error: errorUpdate,
    status: statusUpdate,
    isPending: isPendingUpdate,
  } = useUpdateAggMutation();
  const { data, status: getAggByIdStatus } = useGetAggregatorById({
    enabled: !!params.id,
    id: params.id as string,
  });

  // permissions
  const branchesHavePermission =
    branchesData?.data.filter((branch) =>
      specificBranchHasPermission("add_aggregator", branch.id)
    ) || [];

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (mode === "add") {
      mutate(data);
    } else {
      mutateUpdate({
        id: params.id,
        name: data.name,
        aggregator_fee: data.aggregator_fee,
        payment_methods: data.payment_methods,
      });
    }
  };

  const navigateToAggregators = () => {
    navigate("/aggregators/list");
  };

  //Get agg by Id success and error handling
  useEffect(() => {
    if (getAggByIdStatus === "success") {
      console.log({ data });
      reset({
        branch_id: data.data.branch_id,
        name: data.data.name,
        aggregator_fee: data.data.aggregator_fee,
        payment_methods: data.data.related_payment_methods.map(
          (payment: any) => payment.payment_method?.id
        ),
      });
    }
  }, [getAggByIdStatus]);

  //Add agg success and error handling
  useEffect(() => {
    if (status === "success") {
      navigateToAggregators();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update agg success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: errorUpdate?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Stack height={"100%"}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Stack height={"100%"} justifyContent={"space-between"}>
          <Box p={{ xs: 2, sm: 4 }}>
            <Stack direction={"row"} spacing={"12px"} mb={3}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"60px"}
                height={"60px"}
                bgcolor={"#D0D5DD"}
                borderRadius={"16px"}
              >
                <img src={handshakeIcon} width={"32px"} alt="" />
              </Box>
              <Box>
                <Typography fontSize={"32px"} lineHeight={"38.4px"}>
                  {t("aggs")}
                </Typography>
                {mode === "edit" && (
                  <Typography
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    color={"#98A2B3"}
                  >
                    {t("lastUpdated")}: {data?.data.updated_at}
                  </Typography>
                )}
              </Box>
            </Stack>
            {/* divider */}
            <Box bgcolor={"#F2F4F7"} width={"100%"} />

            <Stack spacing={1} direction={"row"} mb={3} alignItems={"center"}>
              <Typography
                color={"#475467"}
                lineHeight={"19.2px"}
                onClick={navigateToAggregators}
                sx={{ cursor: "pointer" }}
              >
                {t("aggs")}
              </Typography>
              <img src={RightArrow} alt="" />
              <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
                <Typography
                  color={"#344054"}
                  fontWeight={600}
                  lineHeight={"19.2px"}
                >
                  {mode === "add" ? t("addNew") : t("edit")} {t("agg")}
                </Typography>
              </Box>
            </Stack>
            <Stack spacing={1.5} alignItems={"center"} width={"100%"}>
              {/*  Branch Select */}
              <Box sx={{ minWidth: { xs: "100%", md: 872 } }}>
                <FormControl fullWidth size="small" error={!!errors.branch_id}>
                  <InputLabel id="demo-simple-select-label">
                    {t("branchLocation")}
                  </InputLabel>
                  <Controller
                    name="branch_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("branchLocation")}
                        disabled={mode === "edit"}
                      >
                        {branchesHavePermission?.map(({ id, name }) => {
                          return (
                            <MenuItem key={id} value={`${id}`}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.branch_id?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
              {/* Aggregator Name  */}
              <Box sx={{ minWidth: { xs: "100%", md: 872 } }}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("aggName")}
                      {...field}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined,
                      }}
                    />
                  )}
                />
              </Box>
              {/* Aggregator Fee */}
              <Box sx={{ minWidth: { xs: "100%", md: 872 } }}>
                <Controller
                  name="aggregator_fee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={`${t("aggFees")} %`}
                      {...field}
                      error={!!errors.aggregator_fee}
                      helperText={errors.aggregator_fee?.message}
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: field.value !== undefined,
                      }}
                    />
                  )}
                />
              </Box>
              {/* Related payment Select  */}
              <Box sx={{ minWidth: { xs: "100%", md: 872 } }}>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.payment_methods}
                >
                  <InputLabel id="demo-multiple-checkbox-label">
                    {t("selectPayments")}
                  </InputLabel>
                  <Controller
                    name={`payment_methods`}
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        input={<OutlinedInput label={t("selectPayments")} />}
                        renderValue={(selected) =>
                          selected
                            .map(
                              (selectedId) =>
                                paymentsData?.data.find(
                                  (payment) => payment.id === +selectedId
                                )?.name
                            )
                            .join(", ")
                        }
                      >
                        {paymentsData?.data
                          .filter(
                            (pay) =>
                              !pay.has_aggregator ||
                              (mode === "edit" &&
                                data?.data.related_payment_methods
                                  .map((item: any) => item.payment_method.id)
                                  .includes(pay.id))
                          )
                          .map((payment) => (
                            <MenuItem key={payment.id} value={payment.id}>
                              <Checkbox
                                checked={
                                  field.value &&
                                  field.value.indexOf(payment.id) > -1
                                }
                              />
                              {payment.name}
                            </MenuItem>
                          ))}
                        {paymentsData?.data.filter((pay) => !pay.is_aggregator)
                          .length === 0 && (
                          <Typography align="center">
                            {t("noPaysAvailable")}
                          </Typography>
                        )}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.payment_methods?.message}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Stack>
          </Box>

          <Box
            p={{ xs: " 24px 16px", sm: 4 }}
            bgcolor={"#FCFCFD"}
            display={"flex"}
            justifyContent={"center"}
            boxShadow={"0 -8px 50px -5px rgba(50, 50, 50, 0.1)"}
          >
            <Stack
              direction={"row"}
              sx={{ minWidth: { xs: "100%", md: 872 } }}
              justifyContent={"space-between"}
            >
              <Button
                variant="outlined"
                color="tertiary"
                onClick={navigateToAggregators}
              >
                {t("cancel")}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                endIcon={<KeyboardArrowRightIcon />}
                loading={isPending || isPendingUpdate}
              >
                {t("submit")}
              </LoadingButton>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Stack>
  );
};

export default AddNewAggregator;
