import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import {
  useAddItemMutation,
  useGetSku,
  useGetUnitsList,
  useUpdateItemMutation,
} from "src/api/purchasing/items";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const schema = yup.object().shape({
  name: yup.string().required("Required"),
  sku: yup.string().required("Required"),
  unit: yup.number().required("Required"),
  shelf_life: yup.number(),
  type: yup.string().required("Required"),
  fixed_cost: yup.string().required("Required"),
  unit_cost: yup.string().when("fixed_cost", {
    is: "yes",
    then: (value) => value.required("Required"),
  }),
});

interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  itemToUpdate?: any;
  refetch: Function;
}

const AddEditItemPopup = (props: IProps) => {
  const { open, handleClose, itemToUpdate, refetch } = props;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { data: unitsData } = useGetUnitsList();
  const { mutate, status, error, isPending } = useAddItemMutation();
  const { data } = useGetSku();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateItemMutation();

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const mode = !!itemToUpdate ? "edit" : "add";

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const dataToSend = {
      name: data.name,
      sku: data.sku,
      type: data.type,
      unit_id: data.unit,
      shelf_life: data.shelf_life,
      unit_cost: data.fixed_cost === "yes" ? Number(data.unit_cost) : 0,
    };
    if (mode === "add") {
      mutate(dataToSend);
    } else {
      mutateUpdate({
        id: itemToUpdate.id,
        ...dataToSend,
      });
    }
  };

  useEffect(() => {
    if (data && mode === "add") {
      setValue("sku", data?.sku);
    }
  }, [data]);

  useEffect(() => {
    if (!!itemToUpdate) {
      reset({
        name: itemToUpdate.name || "",
        sku: itemToUpdate.sku || "",
        unit: itemToUpdate.unit?.id || "",
        shelf_life: itemToUpdate.shelf_life || "",
        type: itemToUpdate.type,
        fixed_cost: itemToUpdate.unit_cost > 0 ? "yes" : "no",
        unit_cost: itemToUpdate.unit_cost,
      });
    }
  }, [itemToUpdate]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  console.log({ itemToUpdate });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addItem") : t("editItem")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* name */}
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("name")}
                  size="small"
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            {/* sku */}
            <Controller
              name="sku"
              control={control}
              render={({ field }) => (
                <TextField
                  type="string"
                  fullWidth
                  label={t("SKU")}
                  size="small"
                  {...field}
                  error={!!errors.sku}
                  helperText={errors.sku?.message}
                  InputLabelProps={{
                    shrink: field.value !== undefined ? true : false,
                  }}
                />
              )}
            />
            {/* ingredients_unit */}
            <FormControl fullWidth size="small" error={!!errors.unit}>
              <InputLabel id="demo-simple-select-label">
                {t("ingredientsUnit")}
              </InputLabel>
              <Controller
                name="unit"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("ingredientsUnit")}
                  >
                    {unitsData?.data.map((unit: any) => {
                      return (
                        <MenuItem key={unit.id} value={unit.id}>
                          {unit.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.unit?.message}
              </FormHelperText>
            </FormControl>
            {/*  shelf_life */}
            <Controller
              name="shelf_life"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  fullWidth
                  label={t("shelf")}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t("days")}
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                  error={!!errors.shelf_life}
                  helperText={errors.shelf_life?.message}
                />
              )}
            />
            {/* direct_cost */}
            <FormControl fullWidth size="small" error={!!errors.type}>
              <InputLabel id="demo-simple-select-label">
                {t("directIndirectCost")}
              </InputLabel>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    key={field.value}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("directIndirectCost")}
                  >
                    <MenuItem value={"direct"}>{t("direct")}</MenuItem>
                    <MenuItem value={"indirect"}>{t("nonDc")}</MenuItem>
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.type?.message}
              </FormHelperText>
            </FormControl>
            <Box>
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={{ xs: 1, sm: 1, md: 3 }}
                flexWrap={"wrap"}
                width={"100%"}
              >
                <Typography color={"textSecondary"}>
                  {t("fixedCost")}
                </Typography>
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.fixed_cost}
                  sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
                >
                  <Controller
                    name={"fixed_cost"}
                    control={control}
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                      >
                        <FormControlLabel
                          value="yes"
                          control={
                            <Radio
                              icon={<CircleOutlinedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={generalT("yes")}
                          sx={{ color: "#98A2B3" }}
                        />
                        <FormControlLabel
                          value="no"
                          control={
                            <Radio
                              icon={<CircleOutlinedIcon />}
                              checkedIcon={<CheckCircleIcon />}
                            />
                          }
                          label={generalT("no")}
                          sx={{ color: "#98A2B3" }}
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>
              <FormHelperText error id="my-helper-text" sx={{ mt: -1, px: 2 }}>
                {errors.fixed_cost?.message}
              </FormHelperText>
            </Box>

            {/*  Unit Cost */}
            {watch("fixed_cost") === "yes" && (
              <Controller
                name="unit_cost"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    fullWidth
                    label={t("unitCost")}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {generalT("sar")}
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                    error={!!errors.unit_cost}
                    helperText={errors.unit_cost?.message}
                  />
                )}
              />
            )}
          </Stack>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {t("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditItemPopup;
