import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import CreditIcon from "src/assets/svg-icons/purchasing/credit.svg";
import AdvancedBillsIcon from "src/assets/svg-icons/purchasing/advanced_bills.svg";
import SupplierIcon from "src/assets/svg-icons/purchasing/supplier.svg";
import BillsNoTaxIcon from "src/assets/svg-icons/purchasing/bills_no_tax.svg";
import PersonIcon from "src/assets/svg-icons/purchasing/person.svg";
import BillsIcon from "src/assets/svg-icons/purchasing/bills.svg";
import ProfitChart from "./profit-chart";
import StatisticsCard from "./statistics-card";
import {
  useGetPurchasingStatistics,
  useGetPurchasingItemsReport,
} from "src/api/purchasing/summary";
import DatePicker from "react-datepicker";

const today = moment();
const startOfCurrentQuarter = today.clone().startOf("quarter");

const Summary = () => {
  const { t } = useTranslation("purchasing");
  const [items, setItems] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(
    startOfCurrentQuarter.toDate()
  );

  const quarterStart = startDate
    ? moment(startDate)?.clone().startOf("quarter").format("yyyy-MM-DD")
    : null;
  const quarterEnd = startDate
    ? moment(startDate)?.clone().endOf("quarter").format("yyyy-MM-DD")
    : null;

  // APIS
  const { data } = useGetPurchasingStatistics({
    start_date: quarterStart,
    end_date: quarterEnd,
  });
  const {
    data: itemsData,
    refetch,
    isFetching,
  } = useGetPurchasingItemsReport({
    start_date: quarterStart,
    end_date: quarterEnd,
    ...(items.length > 0 ? { items: items.map((item) => item.id) } : {}),
  });

  useEffect(() => {
    refetch();
  }, [items]);

  return (
    <Stack spacing={3} pb={8}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("inventorySummary")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {/*  Quarter Select  */}
          <Box>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              startDate={startDate}
              dateFormat="yyyy, QQQ"
              showQuarterYearPicker
            />
          </Box>

          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
        </Stack>
      </Stack>
      <ProfitChart
        data={itemsData?.dates}
        items={items}
        setItems={setItems}
        isFetching={isFetching}
        currentQuarter={itemsData?.current_quarter}
        prevQuarter={itemsData?.previous_quarter}
      />
      <Box flexGrow={1}>
        {/* credit */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <StatisticsCard title={t("creditTotal")} icon={CreditIcon}>
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  mt={2}
                  borderBottom={"1px solid #E0E0E0"}
                  pb={1}
                >
                  <Typography
                    fontSize={"14px"}
                    fontWeight={600}
                    lineHeight={"16.8px"}
                    color={"#1D2939"}
                  >
                    {t("total")}:
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={600}
                    lineHeight={"16.8px"}
                    color={"#1D2939"}
                  >
                    {data?.data.credit.total} {t("sar")}
                  </Typography>
                </Box>

                {data?.data.credit.suppliers.map((supp: any, index: number) => (
                  <Stack
                    key={`${index}_${supp.supplier}`}
                    direction={"row"}
                    borderBottom={
                      index === data?.data.credit.suppliers.length - 1
                        ? ""
                        : "1px solid #E0E0E0"
                    }
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    py={1}
                  >
                    <Typography
                      fontSize={"14px"}
                      lineHeight={"16.8px"}
                      color={"#1D2939"}
                    >
                      {supp.supplier}
                    </Typography>

                    <Typography
                      fontSize={"14px"}
                      lineHeight={"16.8px"}
                      color={"#1D2939"}
                    >
                      {supp.credit} {t("sar")}
                    </Typography>
                  </Stack>
                ))}
              </>
            </StatisticsCard>
          </Grid>
          {/* advanced */}
          <Grid item xs={12} md={4}>
            <StatisticsCard title={t("advanceBills")} icon={AdvancedBillsIcon}>
              <>
                <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={600}
                    lineHeight={"16.8px"}
                    color={"#1D2939"}
                  >
                    {t("total")}:
                  </Typography>
                  <Typography
                    fontSize={"14px"}
                    fontWeight={600}
                    lineHeight={"16.8px"}
                    color={"#1D2939"}
                  >
                    {data?.data.advanced.total} {t("sar")}
                  </Typography>
                </Box>

                {data?.data.advanced.suppliers.map(
                  (supp: any, index: number) => (
                    <Stack
                      key={`${index}_${supp.supplier}`}
                      direction={"row"}
                      borderBottom={
                        index === data?.data.advanced.suppliers.length - 1
                          ? ""
                          : "1px solid #E0E0E0"
                      }
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      py={1}
                    >
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"16.8px"}
                        color={"#1D2939"}
                      >
                        {supp.supplier}
                      </Typography>

                      <Typography
                        fontSize={"14px"}
                        lineHeight={"16.8px"}
                        color={"#1D2939"}
                      >
                        {supp.advanced} {t("sar")}
                      </Typography>
                    </Stack>
                  )
                )}
              </>
            </StatisticsCard>
          </Grid>
          {/* bills */}
          <Grid item xs={12} md={4}>
            <StatisticsCard title={t("billsHasNoTax")} icon={BillsNoTaxIcon}>
              <Stack spacing={1} mt={2}>
                {[
                  { label: "billQnty", value: "quantity" },
                  { label: "totalBill", value: "total" },
                  { label: "taxLoss", value: "loss" },
                ].map((item) => (
                  <Box
                    p={1.5}
                    bgcolor={"#F2F4F7"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    borderRadius={1}
                  >
                    <Stack
                      direction={"row"}
                      spacing={"6px"}
                      alignItems={"center"}
                    >
                      <img src={BillsIcon} alt="" />
                      <Typography
                        fontSize={"13px"}
                        fontWeight={500}
                        color={"#344054"}
                        lineHeight={"15.6px"}
                      >
                        {t(item.label)}
                      </Typography>
                    </Stack>

                    <Typography
                      fontSize={"13px"}
                      fontWeight={700}
                      color={"#344054"}
                      lineHeight={"15.6px"}
                    >
                      {data?.data.bills[item.value]}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </StatisticsCard>
          </Grid>
          {/* purchase */}
          <Grid item xs={12} md={4}>
            <StatisticsCard
              title={t("mostSupplierPurchasing")}
              icon={SupplierIcon}
            >
              <Stack spacing={1} mt={2}>
                {data?.data.purchase.suppliers.map(
                  (supp: any, index: number) => (
                    <Box
                      key={`${index}_${supp.supplier}`}
                      p={1.5}
                      bgcolor={"#F2F4F7"}
                      display={"flex"}
                      justifyContent={"space-between"}
                      borderRadius={1}
                      alignItems={"center"}
                    >
                      <Stack
                        direction={"row"}
                        spacing={"6px"}
                        alignItems={"center"}
                      >
                        <img src={PersonIcon} alt="" />
                        <Typography
                          fontSize={"13px"}
                          fontWeight={500}
                          color={"#344054"}
                          lineHeight={"15.6px"}
                        >
                          {supp.supplier}
                        </Typography>
                      </Stack>

                      <Typography
                        fontSize={"13px"}
                        fontWeight={700}
                        color={"#344054"}
                        lineHeight={"15.6px"}
                      >
                        {supp.purchase} {t("sar")}
                      </Typography>
                    </Box>
                  )
                )}
              </Stack>
            </StatisticsCard>
          </Grid>

          {/* fluctuation */}
          <Grid item xs={12} md={4}>
            <StatisticsCard title={t("topItemsFluctuations")}>
              <>
                {data?.data.fluctuation.items.map(
                  (item: any, index: number) => (
                    <Stack
                      key={`${index}_${item.supplier}`}
                      direction={"row"}
                      borderBottom={
                        index === data?.data.fluctuation.items.length - 1
                          ? ""
                          : "1px solid #E0E0E0"
                      }
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      py={1}
                    >
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"16.8px"}
                        color={"#1D2939"}
                      >
                        {item.name}
                      </Typography>

                      <Typography
                        fontSize={"14px"}
                        lineHeight={"16.8px"}
                        color={"#1D2939"}
                      >
                        {item.fluctuation}%
                      </Typography>
                    </Stack>
                  )
                )}
              </>
            </StatisticsCard>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
export default Summary;
