import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { authState } from "src/store/auth";
import { useUpdateCompanyLockDate } from "src/api/generic";

interface IProps {
  open: boolean;
  handleClose: () => void;
  refetch?: Function;
}

const schema = yup.object().shape({
  date: yup.date().required("required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const LockDatePopup = (props: IProps) => {
  const { open, handleClose, refetch = () => {} } = props;
  const { t } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [auth, setAuth] = useRecoilState(authState);
  const { user } = auth;

  // APIS
  const { mutate, status, error, isPending } = useUpdateCompanyLockDate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      date: user.company?.close_date,
    },
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    const { date } = data;

    mutate({
      id: user.company.id,
      close_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  //Update success and error handling
  useEffect(() => {
    if (status === "success") {
      const newAuth = {
        ...auth,
        user: {
          ...user,
          company: {
            ...user.company,
            close_date: moment(getValues("date")).format("YYYY-MM-DD"),
          },
        },
      };
      setAuth(newAuth);
      handleClose();
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  useEffect(() => {
    reset({ date: moment(user.company.close_date).toDate() });
  }, [user]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 537,
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {generalT("lockDate")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!errors.date,
                      helperText: t(`${errors.date?.message ?? ""}`),
                      size: "small",
                    },
                  }}
                  format={"dd/MM/yyyy"}
                  label={generalT("lockDate")}
                />
              )}
            />
          </Stack>
          <Stack mt={3} spacing={2} direction={"row"}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              fullWidth
              loading={isPending}
            >
              {generalT("submit")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default LockDatePopup;
