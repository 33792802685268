import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import {
  useGetDeletedItemsList,
  useRestoreItemMutation,
} from "src/api/purchasing/items";

const DeletedItemTable = () => {
  const { t } = useTranslation("purchasing");
  const { t: T } = useTranslation("general");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { data, isFetching, refetch } = useGetDeletedItemsList({
    page: pageNumber + 1,
  });
  const { mutate, isPending, status, error } = useRestoreItemMutation();

  const emptyDataArr = data?.data.length === 0;

  const HeadText = [
    t("name"),
    t("SKU"),
    t("ingredientsUnit"),
    t("type"),
    t("shelfLife"),
    t("createdBy"),
    t("createdAt"),
    t("actions"),
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  useEffect(() => {
    if (status === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: T("restoredSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || T("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Stack
          direction={"row"}
          gap={2}
          flexWrap={"wrap"}
          mt={{ xs: 2, md: 0 }}
        >
          <Button
            color="tertiary"
            variant="outlined"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
          >
            {t("filter")}
          </Button>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
        </Stack>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell
                  key={item}
                  align={"center"}
                  sx={{
                    bgcolor: "#F9FAFB",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    color={"#475467"}
                    lineHeight={"18px"}
                    fontWeight={500}
                  >
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching ? (
            <TableLoadingSkeleton rowsLength={15} cellsLength={8} />
          ) : (
            <TableBody>
              {data.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.sku}</TableCell>
                  <TableCell align="center">{row.unit?.id}</TableCell>
                  <TableCell align="center">
                    {t(row.type === "direct" ? "direct" : "nonDc")}
                  </TableCell>
                  <TableCell align="center">{row.shelf_life}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="right">
                    <LoadingButton
                      variant="outlined"
                      color="tertiary"
                      onClick={() => mutate(row.id)}
                      loading={isPending}
                    >
                      {t("restore")}
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Stack>
  );
};
export default DeletedItemTable;
