import { IconButton, Stack } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import AddEditContributorPopup from "../../add-edit-contributor";
import { useDeleteCapitalMutation } from "src/api/settings/capital";
import { SettingsPermissions } from "src/constants/permissions";
import { oneBranchHasPermission } from "src/utils";

const TableActions = ({
  rowId,
  refetch,
  capitalData,
}: {
  rowId: number;
  refetch: Function;
  capitalData: any;
}) => {
  const [open, setOpen] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("general");

  // APIS
  const { mutate, isPending, status, error } = useDeleteCapitalMutation();

  // permissions
  const editPermission = oneBranchHasPermission(
    SettingsPermissions.editCapital
  );
  const deletePermission = oneBranchHasPermission(
    SettingsPermissions.deleteCapital
  );

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => {
    mutate(rowId);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      {deletePermission && (
        <IconButton onClick={handleClickOpen}>
          <img src={DeleteIcon} alt="" />
        </IconButton>
      )}

      <ConfirmPopup
        open={open}
        item={capitalData.name}
        handleClose={handleClose}
        handleConfirm={handleDelete}
        isPending={isPending}
      />

      {openEditModal && (
        <AddEditContributorPopup
          open={openEditModal}
          handleClose={handleCloseEditModal}
          refetch={refetch}
          capitalData={capitalData}
        />
      )}
    </Stack>
  );
};
export default TableActions;
