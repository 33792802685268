import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  photographInvoicesAndRelaxFeatures,
  professionalAccountantFeatures,
  professionalAndBenefitsAccountantFeatures,
} from "../constants";
import ItemInfo from "./info-item";
import {
  useAddBranchPackageMutation,
  useGetAllPackages,
} from "src/api/add-sales";

interface IModel {
  open: boolean;
  handleClose: any;
  branchId: string;
  setAddedPackageToBranch: Function;
  refetchBranches: Function;
}
const BranchPackagePopup = (props: IModel) => {
  const {
    open,
    handleClose,
    branchId,
    setAddedPackageToBranch,
    refetchBranches,
  } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [selectedPackage, setSelectedPackage] = useState();
  const { t } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");

  // APIS
  const {
    mutate: mutateAdd,
    isPending: isPendingAdd,
    status: statusAdd,
    error: errorAdd,
  } = useAddBranchPackageMutation();

  const { data, isFetching } = useGetAllPackages();

  const handleSave = () => {
    if (!selectedPackage) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: "Select Package",
        },
      ]);
      return;
    }
    mutateAdd({
      branch_id: +branchId,
      package_id: selectedPackage,
      duration: 1,
    });
  };

  useEffect(() => {
    if (statusAdd === "success") {
      refetchBranches();
      handleClose();
      setAddedPackageToBranch(true);
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (statusAdd === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorAdd?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusAdd]);

  const featuresList = [
    professionalAccountantFeatures,
    professionalAndBenefitsAccountantFeatures,
    photographInvoicesAndRelaxFeatures,
  ];

  const featuresNames = [
    "professionalAccountant",
    "professionalAndCostAccountant",
    "photographInvoicesAndRelax",
  ];

  const featuresDescriptions = [
    "professionalAccountantDescription",
    "professionalAndCostAccountantDescription",
    "noManualInvoiceInput",
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="attachments"
      aria-describedby="attachments-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100%", sm: 1047 },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"}>
            {t("Add package for this branch")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          {isFetching ? (
            <Box
              minHeight={"500px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={{ xs: 1, lg: 3 }}>
              {/* professional accountant */}
              {data?.data
                .filter((packToFilter: any) => packToFilter.status)
                .map((pack: any, index: number) => {
                  return (
                    <Grid
                      key={pack.id}
                      item
                      xs={12}
                      md={4}
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <Paper
                        elevation={2}
                        sx={{
                          p: { xs: 2, lg: 4 },
                          width: "100%",
                          borderRadius: "20px",
                          boxShadow: "none",
                          mb: "29px",
                          border:
                            selectedPackage === pack.id
                              ? "2px solid #FF8D3F"
                              : "2px solid #EAEFF4",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedPackage(pack.id)}
                      >
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          mb={"16px"}
                          flexDirection={{ xs: "column", sm: "row" }}
                        >
                          <Typography
                            fontSize={"36px"}
                            color={"#2B3674"}
                            lineHeight={"44px"}
                            fontWeight={700}
                            mb={{ xs: 2, md: 0 }}
                          >
                            {pack.price} {t("sar")}.{" "}
                            <Typography
                              component={"span"}
                              color={"#474747"}
                              fontSize={"20px"}
                              fontWeight={400}
                              lineHeight={"28px"}
                            >
                              {t("monthly")}
                            </Typography>
                          </Typography>
                        </Box>
                        <Typography
                          color={"#2B3674"}
                          fontSize={"16px"}
                          fontWeight={700}
                          lineHeight={"28px"}
                          mb={1}
                        >
                          {t(featuresNames[index])}
                        </Typography>
                        <Typography color={"#474747"} lineHeight={"20px"}>
                          {t(featuresDescriptions[index])}
                        </Typography>
                        {/* divider */}
                        <Box
                          width={"100%"}
                          height={"1px"}
                          bgcolor={"#E2E2E2"}
                          my={"18px"}
                        />
                        <Stack spacing={1}>
                          {featuresList[index].map((item) => (
                            <ItemInfo title={t(item)} />
                          ))}
                        </Stack>
                      </Paper>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Box>

        <Stack spacing={2} direction={"row"}>
          <Button
            variant="outlined"
            color="tertiary"
            fullWidth
            onClick={handleClose}
          >
            {generalT("cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleSave}
            fullWidth
            loading={isPendingAdd}
          >
            {generalT("save")}
          </LoadingButton>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default BranchPackagePopup;
