import { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  useAddEventMutation,
  useUpdateEventMutation,
} from "src/api/purchasing/events";
import FilePondUploader from "src/shared/components/file-pond-uploader";
import { useGetPaymentMethods } from "src/api/generic";
import { useGetSuppliersList } from "src/api/purchasing/suppliers";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useGetCapitalList } from "src/api/settings/capital";
import { useGetOCAccountsList } from "src/api/operation-cost/accounts";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { authState } from "src/store/auth";

interface IProps {
  open: boolean;
  handleClose: () => void;
  eventToEdit?: any;
  refetch: Function;
}

const AddEditEventPopup = (props: IProps) => {
  const { open, handleClose, eventToEdit, refetch } = props;
  const { t } = useTranslation("purchasing");
  const { t: generalT } = useTranslation("general");
  const [files, setFiles] = useState<any>([]);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [auth] = useRecoilState(authState);
  const { user } = auth;
  const companyCloseDate = user.company?.close_date;

  const schema = yup.object().shape({
    type: yup
      .string()
      .required(t("required"))
      .oneOf(["owner", "supplier", "payment", "liability"] as const),
    mode: yup
      .string()
      .required(t("required"))
      .oneOf(["add", "edit"] as const),
    payment_from_id: yup.string().when("type", {
      is: (type: string) =>
        type === "payment" || type === "supplier" || type === "liability",
      then: (value) => value.required(t("required")),
    }),
    payment_to_id: yup.string().when("type", {
      is: (type: string) => type === "payment",
      then: (value) => value.required(t("required")),
    }),
    operation: yup.string().when("type", {
      is: (type: string) =>
        type === "supplier" || type === "owner" || type === "liability",
      then: (value) =>
        value
          .required(t("required"))
          .oneOf([
            "advanced",
            "received",
            "to_owner",
            "from_owner",
            "settlement",
            "government_tax",
          ] as const),
    }),
    amount: yup.string().required(t("required")),
    date: yup.date().required(t("required")),
    note: yup.string(),
    supplier_id: yup.string().when(["type", "ownerWith"], {
      is: (type: string, ownerWith: string) =>
        type === "supplier" || (type === "owner" && ownerWith === "supplier"),
      then: (value) => value.required(t("required")),
      otherwise: (value) => value.notRequired(),
    }),

    ownerWith: yup.string().when("type", {
      is: (type: string) => type === "owner",
      then: (value) =>
        value
          .required(t("required"))
          .oneOf(["payment", "supplier", "liability"] as const),
    }),
    capital_id: yup.string().when("type", {
      is: (type: string) => type === "owner",
      then: (value) => value.required(t("required")),
    }),
    account_id: yup.string().when(["type", "ownerWith", "operation"], {
      is: (type: string, ownerWith: string, operation: string) =>
        (type === "liability" && operation !== "government_tax") ||
        (type === "owner" && ownerWith === "liability"),
      then: (value) => value.required(t("required")),
      otherwise: (value) => value.notRequired(),
    }),
  });

  interface FormInputs extends yup.InferType<typeof schema> {}

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
    setValue,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      mode: "add",
    },
  });

  // APIS
  const { mutate, isPending, status, error } = useAddEventMutation();
  const {
    mutate: mutateUpdate,
    status: statusUpdate,
    error: errorUpdate,
    isPending: isPendingUpdate,
  } = useUpdateEventMutation();
  const { data: paymentsData } = useGetPaymentMethods();
  const { data: suppliersData } = useGetSuppliersList({});
  const { data: capitalData } = useGetCapitalList();
  const { data: accountData } = useGetOCAccountsList();

  const mode = getValues("mode");
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    console.log({ data });

    const {
      type,
      date,
      amount,
      payment_from_id,
      operation,
      supplier_id,
      note,
      capital_id,
      account_id,
      payment_to_id,
    } = data;
    // same payment validation
    if (type === "payment" && payment_from_id === payment_to_id) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("notSameFromAndToPayments"),
        },
      ]);
      return;
    }
    // Company Lock date validation
    if (
      !!companyCloseDate &&
      moment(watch("date")).isSameOrBefore(moment(companyCloseDate))
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: `${generalT(
            "canNotAddEditOrDeleteBeforeLockDateCompany"
          )}: ${companyCloseDate}`,
        },
      ]);
      return;
    }
    const toSentData: any = {
      type: type,
      amount: +amount,
      date: moment(date).format("YYYY-MM-DD"),
      ...((type === "payment" ||
        (type === "supplier" &&
          (watch("operation") === "advanced" ||
            watch("operation") === "settlement")) ||
        (type === "liability" &&
          (watch("operation") === "advanced" ||
            watch("operation") === "settlement" ||
            watch("operation") === "government_tax")) ||
        (type === "owner" &&
          watch("ownerWith") === "payment" &&
          watch("operation") === "to_owner")) && {
        payment_from_id: payment_from_id,
      }),
      ...(data.type === "payment" && {
        payment_to_id: data.payment_to_id,
      }),
      ...(((type === "supplier" && watch("operation") === "received") ||
        (type === "liability" && watch("operation") === "received") ||
        (type === "owner" &&
          watch("ownerWith") === "payment" &&
          watch("operation") === "from_owner")) && {
        payment_to_id: payment_from_id,
      }),
      ...((type === "supplier" || type === "owner") && {
        operation: operation,
      }),
      // account operation
      ...(type === "liability" && {
        operation:
          operation === "advanced"
            ? "to_account"
            : operation === "settlement"
            ? "settlement"
            : operation === "government_tax"
            ? "government_tax"
            : "from_account",
      }),
      ...((type === "supplier" ||
        (type === "owner" && watch("ownerWith") === "supplier")) && {
        supplier_id: supplier_id,
      }),
      ...(note && { note: note }),
      // owner
      ...(type === "owner" && { capital_id: capital_id }),
      // account
      ...(((type === "liability" && operation !== "government_tax") ||
        (type === "owner" && watch("ownerWith") === "liability")) && {
        account_id: account_id,
      }),
    };

    const formData = new FormData();
    // append data
    for (const key in toSentData) {
      formData.append(key, toSentData[key]);
    }
    // append files
    if (files.length > 0) {
      files.forEach((file: any, index: number) => {
        formData.append(`files[${index}]`, file);
      });
    }
    if (mode === "add") {
      mutate(formData);
    } else {
      mutateUpdate({ ...toSentData, id: eventToEdit.id });
    }
  };

  const onClose = () => {
    handleClose();
    reset();
  };

  useEffect(() => {
    setValue("operation", undefined);
  }, [watch("type")]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      onClose();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  const liabilityAccountLabel = `${t(
    watch("operation") === "advanced" || watch("operation") === "settlement"
      ? "to"
      : watch("operation") === "received"
      ? "from"
      : ""
  )} ${t("liabilitiesAccount")}`;

  const supplierLabel = `${t(
    watch("operation") === "advanced" || watch("operation") === "settlement"
      ? "to"
      : watch("operation") === "received"
      ? "from"
      : ""
  )} ${t("supplier")}`;

  const paymentFromLabel = `${t(
    (watch("type") === "supplier" || watch("type") === "liability") &&
      watch("operation") === "received"
      ? "to"
      : ((watch("type") === "supplier" || watch("type") === "liability") &&
          (watch("operation") === "advanced" ||
            watch("operation") === "settlement" ||
            watch("operation") === "government_tax")) ||
        watch("type") === "payment"
      ? "from"
      : ""
  )} ${t("payment")}`;

  console.log({ errors }, getValues());

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addEvent") : t("editEvent")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            fullWidth
            size="small"
            error={!!errors.type}
            disabled={mode === "edit"}
          >
            <InputLabel id="demo-simple-select-label">
              {t("eventType")}
            </InputLabel>
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <Select {...field} label={t("eventType")}>
                  <MenuItem value="owner">{t("eventOfOwner")}</MenuItem>
                  <MenuItem value="supplier">{t("eventOfSupplier")}</MenuItem>
                  <MenuItem value="payment">{t("eventOfPayment")}</MenuItem>
                  <MenuItem value="liability">
                    {t("eventOfLiabilities")}
                  </MenuItem>
                </Select>
              )}
            />
            <FormHelperText id="my-helper-text">
              {errors.type?.message}
            </FormHelperText>
          </FormControl>
          {watch("type") && (
            <>
              {/* advance or receive */}
              {(watch("type") === "supplier" ||
                watch("type") === "owner" ||
                watch("type") === "liability") && (
                <Stack mt={2} width={"100%"}>
                  <Typography
                    fontSize={"14px"}
                    lineHeight={"14px"}
                    color="#101828"
                  >
                    {t("type")}
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.operation}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="operation"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          {/* supplier or account types */}
                          {watch("type") === "supplier" ||
                          watch("type") === "liability" ? (
                            <>
                              <FormControlLabel
                                value="advanced"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={`${
                                  watch("type") === "supplier"
                                    ? t("advancePayment")
                                    : `${t("to")} ${t("liabilitiesAccount")}`
                                }`}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                              <FormControlLabel
                                value="received"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={`${
                                  watch("type") === "supplier"
                                    ? t("paymentReceive")
                                    : `${t("from")} ${t("liabilitiesAccount")}`
                                }`}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                              <FormControlLabel
                                value="settlement"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={t("settlement")}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                              {watch("type") === "liability" && (
                                <FormControlLabel
                                  value="government_tax"
                                  control={
                                    <Radio
                                      size="small"
                                      icon={<CircleOutlinedIcon />}
                                      checkedIcon={<CheckCircleIcon />}
                                    />
                                  }
                                  label={t("dueToGovernmentTax")}
                                  sx={{ color: "#98A2B3" }}
                                  disabled={mode === "edit"}
                                />
                              )}
                            </>
                          ) : (
                            <>
                              {/* owner types */}
                              <FormControlLabel
                                value="to_owner"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={`${t("to")} ${t("owner")}`}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                              <FormControlLabel
                                value="from_owner"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={`${t("from")} ${t("owner")}`}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                            </>
                          )}
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.operation?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              )}

              {/* select owner */}
              {watch("type") === "owner" && (
                <FormControl
                  fullWidth
                  size="small"
                  error={!!errors.capital_id}
                  sx={{ mt: 1 }}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t("owner")}
                  </InputLabel>
                  <Controller
                    name="capital_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={"owner"}
                      >
                        {capitalData?.data.map(({ id, name }: any) => {
                          return (
                            <MenuItem key={id} value={`${id}`}>
                              {name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {errors.capital_id?.message}
                  </FormHelperText>
                </FormControl>
              )}

              {/* owner with payment or supplier */}
              {watch("type") === "owner" && (
                <Stack mt={2} width={"100%"}>
                  <Typography
                    fontSize={"14px"}
                    lineHeight={"14px"}
                    color="#101828"
                  >
                    {t(
                      watch("operation") === "to_owner"
                        ? "from"
                        : watch("operation") === "from_owner"
                        ? "to"
                        : ""
                    )}{" "}
                    {t("paymentOrSupplierOrLiability")}
                  </Typography>
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.ownerWith}
                    sx={{
                      width: "fit-content",
                      ml: { xs: "auto", sm: "unset" },
                    }}
                  >
                    <Controller
                      name="ownerWith"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup
                          {...field}
                          key={field.value}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="payment"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={`${t("payment")}`}
                            sx={{ color: "#98A2B3" }}
                            disabled={mode === "edit"}
                          />
                          <FormControlLabel
                            value="supplier"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={`${t("supplier")}`}
                            sx={{ color: "#98A2B3" }}
                            disabled={mode === "edit"}
                          />
                          <FormControlLabel
                            value="liability"
                            control={
                              <Radio
                                size="small"
                                icon={<CircleOutlinedIcon />}
                                checkedIcon={<CheckCircleIcon />}
                              />
                            }
                            label={`${t("liabilities")}`}
                            sx={{ color: "#98A2B3" }}
                            disabled={mode === "edit"}
                          />
                        </RadioGroup>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.ownerWith?.message}
                    </FormHelperText>
                  </FormControl>
                </Stack>
              )}

              {/* from and to */}
              <Stack
                direction={
                  (watch("type") === "supplier" &&
                    watch("operation") === "received") ||
                  (watch("type") === "liability" &&
                    watch("operation") === "received")
                    ? "column-reverse"
                    : "column"
                }
              >
                {/* payment from */}
                {/* show payment in all cases except type owner with supplier or with liability */}
                {!(
                  watch("type") === "owner" &&
                  (watch("ownerWith") === "supplier" ||
                    watch("ownerWith") === "liability")
                ) && (
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.payment_from_id}
                    sx={{ mt: 1 }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {paymentFromLabel}
                    </InputLabel>
                    <Controller
                      name="payment_from_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          key={field.value}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={paymentFromLabel}
                        >
                          {paymentsData?.data
                            ?.filter((pay) => pay.status)
                            .map(({ id, name }) => {
                              return (
                                <MenuItem key={id} value={`${id}`}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.payment_from_id?.message}
                    </FormHelperText>
                  </FormControl>
                )}

                {/* payment to */}
                {watch("type") === "payment" && (
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.payment_to_id}
                    sx={{ mt: 1 }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {t("to")} {t("payment")}
                    </InputLabel>
                    <Controller
                      name="payment_to_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          key={field.value}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={`${t("to")} ${t("payment")}`}
                        >
                          {paymentsData?.data
                            ?.filter((pay) => pay.status)
                            .map(({ id, name }) => {
                              return (
                                <MenuItem key={id} value={`${id}`}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.payment_to_id?.message}
                    </FormHelperText>
                  </FormControl>
                )}
                {/* Select Supplier */}
                {(watch("type") === "supplier" ||
                  (watch("type") === "owner" &&
                    watch("ownerWith") === "supplier")) && (
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.supplier_id}
                    sx={{ mt: 1 }}
                    disabled={mode === "edit"}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {supplierLabel}
                    </InputLabel>
                    <Controller
                      name="supplier_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          key={field.value}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={supplierLabel}
                        >
                          {suppliersData?.data.map((supplier: any) => {
                            return (
                              <MenuItem key={supplier.id} value={supplier.id}>
                                {supplier.name}
                              </MenuItem>
                            );
                          })}
                          {suppliersData?.data?.length === 0 ? (
                            <Typography align="center" color={"textSecondary"}>
                              {generalT("noData")}
                            </Typography>
                          ) : null}
                        </Select>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.supplier_id?.message}
                    </FormHelperText>
                  </FormControl>
                )}

                {/* Select Account */}
                {((watch("type") === "liability" &&
                  watch("operation") !== "government_tax") ||
                  (watch("type") === "owner" &&
                    watch("ownerWith") === "liability")) && (
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.account_id}
                    sx={{ mt: 1 }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {liabilityAccountLabel}
                    </InputLabel>
                    <Controller
                      name="account_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          key={field.value}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={liabilityAccountLabel}
                        >
                          {accountData?.data.map((account: any) => {
                            return (
                              <MenuItem key={account.id} value={account.id}>
                                {account.name}
                              </MenuItem>
                            );
                          })}
                          {suppliersData?.data?.length === 0 ? (
                            <Typography align="center" color={"textSecondary"}>
                              {generalT("noData")}
                            </Typography>
                          ) : null}
                        </Select>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.account_id?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              </Stack>

              <Stack spacing={1.5} mt={1}>
                {/* <Stack
                  direction={"row"}
                  width={"100%"}
                  alignItems={"flex-start"}
                  justifyContent={"space-between"}
                >
                  {watch("type") === "supplier" && (
                    <>
                      <Typography
                        fontSize={"14px"}
                        lineHeight={"14px"}
                        color="#101828"
                      >
                        {t("plusOrMinus")}
                      </Typography>
                      <FormControl
                        fullWidth
                        size="small"
                        error={!!errors.operation}
                        sx={{
                          width: "fit-content",
                          ml: { xs: "auto", sm: "unset" },
                        }}
                      >
                        <Controller
                          name="operation"
                          control={control}
                          render={({ field }) => (
                            <RadioGroup
                              {...field}
                              key={field.value}
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                            >
                              <FormControlLabel
                                value="plus"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={`+ ${t("plus")}`}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                              <FormControlLabel
                                value="minus"
                                control={
                                  <Radio
                                    size="small"
                                    icon={<CircleOutlinedIcon />}
                                    checkedIcon={<CheckCircleIcon />}
                                  />
                                }
                                label={`- ${t("minus")}`}
                                sx={{ color: "#98A2B3" }}
                                disabled={mode === "edit"}
                              />
                            </RadioGroup>
                          )}
                        />
                        <FormHelperText id="my-helper-text">
                          {errors.operation?.message}
                        </FormHelperText>
                      </FormControl>
                    </>
                  )}
                </Stack> */}

                {/* Amount */}
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      fullWidth
                      label={t("amount")}
                      size="small"
                      {...field}
                      error={!!errors.amount}
                      helperText={errors.amount?.message}
                      disabled={mode === "edit"}
                    />
                  )}
                />

                {/* note */}
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={t("notesOptional")}
                      fullWidth
                      size="small"
                      multiline
                      rows={4}
                      maxRows={4}
                      {...field}
                      error={!!errors.note}
                      helperText={errors.note?.message}
                    />
                  )}
                />
                {/* date */}
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!errors.date,
                          helperText: t(`${errors.date?.message ?? ""}`),
                          size: "small",
                        },
                      }}
                      format={"dd/MM/yyyy"}
                      label={`${t("transactionDateRepeat")}`}
                    />
                  )}
                />
                {/* files */}
                {mode === "add" && (
                  <>
                    <Stack
                      direction={"row"}
                      spacing={"6px"}
                      alignItems={"center"}
                      marginBottom={2}
                    >
                      <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                      <Typography
                        fontSize={"16px"}
                        lineHeight={"19px"}
                        color="#101828"
                      >
                        {t("uploadAttachment")}
                      </Typography>
                    </Stack>
                    <Box width={"100%"} mt={0}>
                      <FilePondUploader
                        onUpload={(e) => setFiles(e)}
                        maxFiles={3}
                        maxFileSize={3}
                        acceptedFileTypes={["image/*", "application/pdf"]}
                      />
                    </Box>
                  </>
                )}
              </Stack>
            </>
          )}
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {generalT("submit")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditEventPopup;
