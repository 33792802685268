import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import TableActions from "./table-actions";
import AddIcon from "@mui/icons-material/Add";
import AddEditUnitPopup from "./add-edit-unit";
import { useGetUnitsList } from "src/api/purchasing/items";
import { InventoryPermissions } from "src/constants/permissions";
import { oneBranchHasPermission } from "src/utils";

const UnitsTable = () => {
  const { t } = useTranslation("purchasing");
  const { t: T } = useTranslation("general");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);

  // APIS
  const { data, isFetching, refetch } = useGetUnitsList({
    page: pageNumber + 1,
  });

  const emptyDataArr = data?.data.length === 0;

  const HeadText = [t("name"), t("createdBy"), t("createdAt"), t("actions")];

  // permissions
  const addPermission = oneBranchHasPermission(InventoryPermissions.addItem);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = (type: any) => {
    setOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Stack
          direction={"row"}
          gap={2}
          flexWrap={"wrap"}
          mt={{ xs: 2, md: 0 }}
        >
          {addPermission && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpen}
              size="small"
            >
              {t("createUnit")}
            </Button>
          )}
        </Stack>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell
                  key={item}
                  align={"center"}
                  sx={{
                    bgcolor: "#F9FAFB",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    color={"#475467"}
                    lineHeight={"18px"}
                    fontWeight={500}
                  >
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching || !data ? (
            <TableLoadingSkeleton rowsLength={5} cellsLength={4} />
          ) : (
            <TableBody>
              {data.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="right">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      <AddEditUnitPopup
        open={open}
        handleClose={handleClose}
        refetch={refetch}
      />
    </Stack>
  );
};
export default UnitsTable;
