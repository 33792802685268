import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  reference_number?: string;
  invoice_number?: string;
  payment_method_id?: string;
  supplier_id?: string;
  total?: string;
  branch_id?: string;
  status?: string;
}

interface ITransBody {
  id?: string;
  amount: number;
  tax: number;
  include_tax: 1 | 0;
  date: string;
  payment_method_id: number;
  note: string;
  branch_id: number;
  account_id: number;
  invoice_number: number;
  files?: any;
  repeat_type:
    | "no_repeat"
    | "daily"
    | "weekly"
    | "monthly"
    | "yearly"
    | "custom";
  repeat_months: number;
}

export const useGetPurchasingList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-purchasing-list",
      params.page,
      params.start_date,
      params.end_date,
      params.reference_number,
      params.invoice_number,
      params.total,
      params.status,
      params.supplier_id,
      params.payment_method_id,
      params.branch_id,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/purchases`, { params });
    },
  });

export const useGetPurchasingById = ({ id }: { id: string }) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-purchase-by-id", id],
    queryFn: async () => {
      return apiClient.get(`/api/purchases/${id}`);
    },
    gcTime: 0,
    enabled: !!id,
  });

export const useDownloadPurchasingList = (params: any) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["download-purchasing-list", params.refNum || ""],
    queryFn: async () => {
      return apiClient.get(`/api/purchases/download`, { params });
    },
    enabled: false,
    gcTime: 0,
  });

export const useAddPurchaseMutation = () =>
  useMutation<void, APIErrorResponse, any>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchases`, data);
    },
  });

export const useImportPurchaseMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchaseImportFiles`, data);
    },
  });

export const useGetPurchaseReferenceNumbers = (branch_id: number) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-purchase-reference-numbers", branch_id],
    queryFn: async () => {
      return apiClient.get(
        `/api/purchases/get-current-reference-numbers?branch_id=${branch_id}`
      );
    },
    gcTime: 0,
    enabled: !!branch_id,
  });

export const useUpdatePurchaseMutation = () =>
  useMutation<void, APIErrorResponse, ITransBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/purchases/${id}`, rest);
    },
  });

export const useDeletePurchaseMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/purchases/${id}`);
    },
  });

// Purchase files
export const useGetPurchaseFiles = (id: number) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-purchase-files", id],
    queryFn: async () => {
      return apiClient.get(`/api/purchaseFiles?purchase_id=${id}`);
    },
    gcTime: 0,
  });

export const useDeletePurchaseFileMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/purchaseFiles/${id}`);
    },
  });

export const useAddPurchaseFileMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchaseFiles`, data);
    },
  });

// payment
export const useAddPurchasePaymentMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchasePayments`, data);
    },
  });

// purchase details page
export const useGetPurchaseById = (id: string) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-purchase-trans-by-id", id],
    queryFn: async () => {
      return apiClient.get(`/api/purchases/${id}`);
    },
  });

export const useDeletePurchasePaymentMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/purchasePayments/${id}`);
    },
  });

// purchase time range
export const useGetPurchasingTimeRange = (id: string) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-purchase-time-range", id],
    queryFn: async () => {
      return apiClient.get(
        `/api/purchases/get-purchase-timerange?branch_id=${id}`
      );
    },
    enabled: !!id,
  });

// Total Purchase by time range
export const useGetTotalPurchaseByDateRangeMutation = () =>
  useMutation<
    any,
    APIErrorResponse,
    {
      branch_id: number;
      start_date: string;
      end_date: string;
    }
  >({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchases/get-total-purchase-by-date`, data);
    },
  });

export const useGetSupplierEvents = (id: number | undefined) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-supplier-events", id],
    queryFn: async () => {
      return apiClient.get(
        `/api/purchaseEvents/get-supplier-purchase-events?supplier_id=${id}`
      );
    },
    enabled: !!id,
  });

export const useGetAccountEvents = (id: number | undefined) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-account-events", id],
    queryFn: async () => {
      return apiClient.get(
        `/api/purchaseEvents/get-account-purchase-events?account_id=${id}`
      );
    },
    enabled: !!id,
  });

export const useGetSupplierItems = (params: {
  supplier_id: string | number;
  enabled: boolean;
}) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-supplier-items-list", params.supplier_id],
    queryFn: async () => {
      const { enabled, ...rest } = params;
      return apiClient.get(`/api/purchaseItems/get-supplier-purchase-items`, {
        params: rest,
      });
    },
    gcTime: 0,
    enabled: params.enabled,
  });

// events payments
export const useAddPurchaseEventPaymentMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchasePurchaseEvents`, data);
    },
  });

export const useDeletePurchaseEventPaymentMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/purchasePurchaseEvents/${id}`);
    },
  });

// check duplicated purchase
interface ICheckPurchaseBody {
  branch_id: number;
  supplier_id: number;
  date: string;
  amount: number;
}
export const useCheckDuplicatedPurchaseMutation = () =>
  useMutation<{ status: boolean }, APIErrorResponse, ICheckPurchaseBody>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchases/check-duplicated-purchase`, data);
    },
  });

interface ICheckPurchaseInvoiceBody {
  branch_id: number;
  supplier_id: number;
  invoice_number: string;
}

export const useCheckDuplicatedPurchaseInvoiceMutation = () =>
  useMutation<{ status: boolean }, APIErrorResponse, ICheckPurchaseInvoiceBody>(
    {
      mutationFn: async (data) => {
        return apiClient.post(
          `/api/purchases/check-duplicated-invoice-number`,
          data
        );
      },
    }
  );
