import { Box, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DateRangeInput from "src/shared/components/date-range";
import CogsChart from "./cogs-chart";
import { useGetCogsSummary } from "src/api/inventory/summary";

const Summary = () => {
  const { t } = useTranslation("purchasing");
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: moment().startOf("month").format("yyyy-MM-DD"),
    endDate: moment().endOf("month").format("yyyy-MM-DD"),
  });
  const [endDateFocused, setEndDateFocused] = useState(false);

  const isOutsideRange = (day: moment.Moment) => {
    // Calculate the difference in days between the start and end dates
    const startDate = dateRange.startDate ? moment(dateRange.startDate) : null;
    const endDate = day ? moment(day) : null;
    const range = startDate && endDate ? endDate.diff(startDate, "days") : null;

    // Return true if the range exceeds 30 days, false otherwise
    return endDateFocused && range !== null && (range < 0 || range > 30);
  };

  // APIS
  const { data } = useGetCogsSummary({
    start_date: dateRange.startDate,
    end_date: dateRange.endDate,
  });

  console.log({ data });

  return (
    <Stack spacing={3} pb={8}>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        gap={2}
        alignItems={"center"}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          textTransform={"uppercase"}
        >
          {t("inventorySummary")}
        </Typography>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          {/*  Data Select  */}
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              }
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null}
              endDateId={`your_unique_end_date_id`} // string;
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
              isOutsideRange={isOutsideRange}
              onFocusChange={(focused: "startDate" | "endDate" | null) => {
                setEndDateFocused(focused === "endDate");
              }}
            />
          </Box>
        </Stack>
      </Stack>
      <CogsChart data={data} />
    </Stack>
  );
};
export default Summary;
