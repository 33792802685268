import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import InventoryIcon from "src/assets/svg-icons/side-bar/inventory.svg";
import Item from "./item";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import * as yup from "yup";
import { useGetItemsList } from "src/api/purchasing/items";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  useAddStockCountMutation,
  useGetCountById,
  useUpdateStockCountMutation,
} from "src/api/inventory/counts";
import CalcItem from "./calculation-item";
import moment from "moment";
import { InventoryPermissions } from "src/constants/permissions";
import { specificBranchHasPermission } from "src/utils";
import { DateTimePicker } from "@mui/x-date-pickers";

const schema = yup.object().shape({
  date: yup.date().required("required"),
  items: yup
    .array()
    .of(
      yup.object().shape({
        item_id: yup.number().required("required"),
        item_name: yup.string(),
        item_sku: yup.string(),
        item_unit: yup.string(),
        raw: yup.number().typeError("Required").required("required"),
        formula1_name: yup.string(),
        formula1_value: yup.string().when("formula1_percentage", {
          is: (value: number) => value > 0,
          then: (value) => value.required("Required"),
        }),
        formula1_percentage: yup.number(),
        formula2_name: yup.string(),
        formula2_value: yup.string().when("formula2_percentage", {
          is: (value: number) => value > 0,
          then: (value) => value.required("Required"),
        }),
        formula2_percentage: yup.number(),
        // calculations
        system_Q: yup.number(),
        diff_cost: yup.number(),
        total: yup.number(),
        confirmed_entered_qnty: yup.number(),
        confirmed_diff_qnty: yup.number(),
        confirmed_diff_percentage: yup.number(),
        net_transfer: yup.number(),
        consumption_quantity: yup.number(),
        consumption_percentage: yup.number(),
        consumption_cost: yup.number(),
      })
    )
    .required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AddNewStocktaking = () => {
  const navigate = useNavigate();
  const { t: generalT } = useTranslation("general");
  const { t } = useTranslation("inventory");
  const { id, brId, brName } = useParams();
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [nextStatus, setNextStatus] = useState<string>("");
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [isSaving, setIsSaving] = useState<boolean>(true);
  const [isInitRecalculate, setIsInitRecalculate] = useState<boolean>(false);
  const mode = !id ? "add" : "edit";
  console.log({ mode });

  // APIS
  const {
    data: countData,
    status: getCountByIdStatus,
    refetch,
    isFetching,
    isLoading,
  } = useGetCountById({
    enabled: !!id,
    id: id as string,
  });
  const { data: itemsData } = useGetItemsList();
  const {
    mutate,
    status,
    error,
    isPending,
    data: addResponseData,
  } = useAddStockCountMutation();
  const {
    mutate: mutateUpdate,
    error: errorUpdate,
    status: statusUpdate,
    isPending: isPendingUpdate,
  } = useUpdateStockCountMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      date: new Date(),
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "items",
    control,
  });

  // permissions
  const confirmedPermission = specificBranchHasPermission(
    InventoryPermissions.confirmStocktaking,
    countData?.data.branch.id
  );
  const addPermission = specificBranchHasPermission(
    InventoryPermissions.addStocktaking,
    countData?.data.branch.id
  );

  const navigateToInventory = () => {
    navigate("/inventory/counts");
  };

  // Handler function when item is selected
  const handleItemSelect = (event: React.SyntheticEvent, newValue: any) => {
    setSelectedItem(newValue);
    if (newValue) {
      append({
        item_id: newValue.id,
        item_name: newValue.name,
        item_sku: newValue.sku,
        item_unit: newValue.unit?.name,
        raw: 0,
        formula1_name: newValue.formula?.field_1_name || "",
        formula1_value: "",
        formula1_percentage: newValue.formula?.field_1_percentage ?? undefined,
        formula2_name: newValue.formula?.field_2_name || "",
        formula2_value: "",
        formula2_percentage: newValue.formula?.field_2_percentage ?? undefined,
        system_Q: 0,
        diff_cost: 0,
        total: 0,
      });
      saveDraft();
      setInputValue("");
      setSelectedItem(null);
    }
  };

  // Handle manual changes to the input field
  const handleInputChange = (
    event: React.SyntheticEvent,
    newInputValue: string
  ) => {
    if (selectedItem === null) {
      setInputValue(newInputValue);
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    // Lock date validation
    if (
      !!countData?.data?.branch?.close_date &&
      moment(watch("date"))
        .startOf("day")
        .isSameOrBefore(
          moment(countData?.data?.branch?.close_date).startOf("day")
        )
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: `${generalT("canNotAddEditOrDeleteBeforeLockDate")}: ${
            countData?.data?.branch?.close_date
          }`,
        },
      ]);
      return;
    }

    const dateFormatted = moment(data.date).format("YYYY-MM-DD HH:mm:ss");

    const itemsToSend: any[] = [];
    data.items.forEach((item) => {
      const enteredTotalQuantity =
        (Number(item.raw) || 0) +
        (Number(item.formula1_value) || 0) *
          (1 + (Number(item.formula1_percentage) || 0) / 100) +
        (Number(item.formula2_value) || 0) *
          (1 + (Number(item.formula2_percentage) || 0) / 100);

      itemsToSend.push({
        item_id: item.item_id,
        raw: item.raw,
        field_1_value:
          item.formula1_percentage && item.formula1_percentage > 0
            ? Number(item.formula1_value)
            : null,
        field_2_value:
          item.formula2_percentage && item.formula2_percentage > 0
            ? Number(item.formula2_value)
            : null,
        entered_quantity: enteredTotalQuantity.toFixed(8),
      });
    });

    if (mode === "add") {
      setNextStatus("sent");
      mutate({
        status: "sent",
        branch_id: brId,
        items: itemsToSend,
        date: dateFormatted,
      });
    } else {
      const statusToChangeTo =
        countData?.data.status === "sent" ? "confirmed" : "sent";
      setNextStatus(statusToChangeTo);
      mutateUpdate({
        status: statusToChangeTo,
        id: id,
        items: itemsToSend,
        date: dateFormatted,
      });
    }

    console.log({ data, itemsToSend });
  };

  // Save as a draft without any validations
  const saveDraft = () => {
    // Lock date validation
    if (
      !!countData?.data?.branch?.close_date &&
      moment(watch("date")).isSameOrBefore(
        moment(countData?.data?.branch?.close_date)
      )
    ) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: `${generalT("canNotAddEditOrDeleteBeforeLockDate")}: ${
            countData?.data?.branch?.close_date
          }`,
        },
      ]);
      return;
    }

    if (!watch("date")) {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: t("selectDatePlease"),
        },
      ]);
      return;
    }

    const data = getValues();
    const dateFormatted = moment(data.date).format("YYYY-MM-DD HH:mm:ss");
    const itemsToSend: any[] = [];
    data.items.forEach((item) => {
      const enteredTotalQuantity =
        (Number(item.raw) || 0) +
        (Number(item.formula1_value) || 0) *
          (1 + (Number(item.formula1_percentage) || 0) / 100) +
        (Number(item.formula2_value) || 0) *
          (1 + (Number(item.formula2_percentage) || 0) / 100);

      itemsToSend.push({
        item_id: item.item_id,
        raw: item.raw || 0,
        field_1_value:
          item.formula1_percentage && item.formula1_percentage > 0
            ? Number(item.formula1_value)
            : null,
        field_2_value:
          item.formula2_percentage && item.formula2_percentage > 0
            ? Number(item.formula2_value)
            : null,
        entered_quantity: enteredTotalQuantity,
      });
    });

    if (mode === "add") {
      mutate({
        status: "draft",
        branch_id: brId,
        items: itemsToSend,
        date: dateFormatted,
      });
    } else {
      mutateUpdate({
        status: countData?.data.status,
        id: id,
        items: itemsToSend,
        date: dateFormatted,
      });
    }
  };

  useEffect(() => {
    if (getCountByIdStatus === "success") {
      console.log({ countData });
      if (isSaving && !isFetching) {
        console.log("resetting", { countData });
        reset({
          date: moment(countData.data.date).toDate(),
          items: countData.data.stock_count_items.map((item: any) => {
            return {
              item_id: item.item?.id,
              item_name: item.item?.name,
              item_sku: item.item?.sku,
              item_unit: item.item?.unit?.name,
              raw: item.raw,
              formula1_name: item.item.formula?.field_1_name || "",
              formula1_value: item.field_1_value || "",
              formula1_percentage: item.item.formula?.field_1_percentage ?? 0,
              formula2_name: item.item.formula?.field_2_name || "",
              formula2_value: item.field_2_value || "",
              formula2_percentage: item.item.formula?.field_2_percentage ?? 0,
              // calculations
              system_Q: item.system_quantity,
              diff_cost: item.diff_cost ?? 0,
              total: item.total,
              confirmed_entered_qnty: item.entered_quantity ?? 0,
              confirmed_diff_qnty: item.diff_quantity ?? 0,
              confirmed_diff_percentage: item.diff_percentage ?? 0,

              net_transfer: item.net_transfer ?? 0,
              consumption_quantity: item.consumtion_quantity ?? 0,
              consumption_percentage: item.consumtion_percentage ?? 0,
              consumption_cost: item.consumtion_cost ?? 0,
            };
          }),
        });
        setIsSaving(false);
        if (!isInitRecalculate && countData?.data.status !== "confirmed") {
          saveDraft();
          setIsInitRecalculate(true);
        }
      }
    }
  }, [getCountByIdStatus, isFetching]);

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      navigate(`/counts/${addResponseData?.data.id}/edit`);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      if (nextStatus === "sent" || nextStatus === "confirmed") {
        navigateToInventory();
        setNotifications([
          ...notifications,
          {
            type: "success",
            message: generalT("updatedSuccessfully"),
          },
        ]);
      } else {
        refetch();
      }
    } else if (statusUpdate === "error") {
      setNextStatus("");
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors?.[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  let totalDiffCost = 0;
  let totalItems = 0;

  countData?.data.stock_count_items.forEach((item: any) => {
    totalDiffCost += item.diff_cost;
    totalItems += item.total;
  });

  const selectedItemsIds = watch("items")?.map((item) => item.item_id);

  console.log({ selectedItemsIds, errors });

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={InventoryIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("inventory")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <Stack spacing={2} direction={"row"} mb={3} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToInventory}
          sx={{ cursor: "pointer" }}
        >
          {t("inventory")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToInventory}
          sx={{ cursor: "pointer" }}
        >
          {t("count")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            {mode === "add" ? generalT("add") : countData?.data.reference}
          </Typography>
        </Box>
      </Stack>

      {isLoading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          minHeight={"250px"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={1} p={2} bgcolor={"#F9FAFB"}>
            <Grid item xs={12}>
              <Item
                label={generalT("branch")}
                value={mode === "add" ? brName : countData?.data.branch.name}
              />
            </Grid>
            <Grid item xs={6}>
              <Item
                label={t("inventory")}
                value={mode === "add" ? brName : countData?.data.branch.name}
              />
            </Grid>
            <Grid item xs={6}>
              <Item label={t("dateSent")} value={countData?.data.sent_at} />
            </Grid>
            {countData?.data.status !== "draft" && (
              <>
                <Grid item xs={6}>
                  <Item
                    label={t("total")}
                    value={`${totalItems.toFixed(3)} SAR`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Item
                    label={t("differentCost")}
                    value={`${totalDiffCost.toFixed(3)} SAR`}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={6}>
              <Item label={t("note")} value="" />
            </Grid>
          </Grid>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <Typography
                  color={"#344054"}
                  fontSize={"14px"}
                  fontWeight={700}
                  lineHeight={"16.8px"}
                >
                  {t("date")}
                </Typography>
                {/* <Controller
                  name={`date`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="datetime-local"
                      variant="outlined"
                      {...field}
                      error={!!errors.date}
                      helperText={errors.date?.message}
                      size="small"
                      disabled={countData?.data.status === "confirmed"}
                    />
                  )}
                /> */}
                <Controller
                  name="date"
                  control={control}
                  render={({ field }) => (
                    <DateTimePicker
                      {...field}
                      slotProps={{
                        textField: {
                          // fullWidth: true,
                          error: !!errors.date,
                          helperText: t(`${errors.date?.message ?? ""}`),
                          size: "small",
                        },
                      }}
                      format={"dd/MM/yyyy hh:mm aa"}
                      disabled={countData?.data.status === "confirmed"}
                    />
                  )}
                />
              </Stack>

              {fields.map((itemField, index) => {
                const enteredTotalQuantity =
                  (Number(watch(`items.${index}`).raw) || 0) +
                  (Number(watch(`items.${index}`).formula1_value) || 0) *
                    (1 +
                      (Number(watch(`items.${index}`).formula1_percentage) ||
                        0) /
                        100) +
                  (Number(watch(`items.${index}`).formula2_value) || 0) *
                    (1 +
                      (Number(watch(`items.${index}`).formula2_percentage) ||
                        0) /
                        100);
                return (
                  <Box
                    key={itemField.id}
                    padding={"14px 16px"}
                    borderRadius={1.5}
                    // border={"2px solid #F0F0F0"}
                    boxShadow={"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}
                  >
                    <Grid container spacing={1.3}>
                      <Grid item xs={12} md={12}>
                        <Stack
                          direction={"row"}
                          gap={1.5}
                          alignItems={"center"}
                          flexWrap={"wrap"}
                        >
                          <Box sx={{ minWidth: 159, width: 159 }}>
                            <Typography
                              color={"#667085"}
                              fontSize={"13px"}
                              lineHeight={"24px"}
                            >
                              {t("item")}
                            </Typography>
                            <Typography
                              color={"#667085"}
                              fontSize={"13px"}
                              lineHeight={"24px"}
                              fontWeight={700}
                            >
                              {itemField.item_name}
                            </Typography>
                          </Box>

                          <Box sx={{ minWidth: 80, width: 80 }}>
                            <Typography
                              color={"#667085"}
                              fontSize={"13px"}
                              lineHeight={"24px"}
                            >
                              {t("sku")}
                            </Typography>
                            <Typography
                              color={"#667085"}
                              fontSize={"13px"}
                              lineHeight={"24px"}
                              fontWeight={700}
                              whiteSpace={"nowrap"}
                            >
                              {itemField.item_sku}
                            </Typography>
                          </Box>

                          <Controller
                            name={`items.${index}.raw`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                type="number"
                                label={`${t("raw")}`}
                                variant="outlined"
                                {...field}
                                error={!!errors.items?.[index]?.raw}
                                helperText={errors.items?.[index]?.raw?.message}
                                fullWidth
                                size="small"
                                sx={{ minWidth: 170, width: 170 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Typography fontSize={"14px"}>
                                        {itemField.item_unit}
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                disabled={
                                  countData?.data.status === "confirmed"
                                }
                              />
                            )}
                          />

                          {itemField.formula1_percentage &&
                          itemField.formula1_percentage > 0 ? (
                            <Controller
                              name={`items.${index}.formula1_value`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  type="number"
                                  label={`${itemField.formula1_name} ${itemField.formula1_percentage}% (${itemField.item_unit})`}
                                  variant="outlined"
                                  {...field}
                                  error={
                                    !!errors.items?.[index]?.formula1_value
                                  }
                                  helperText={
                                    errors.items?.[index]?.formula1_value
                                      ?.message
                                  }
                                  fullWidth
                                  size="small"
                                  sx={{ minWidth: 170, width: 170 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography fontSize={"14px"}>
                                          {itemField.item_unit}
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={
                                    countData?.data.status === "confirmed"
                                  }
                                />
                              )}
                            />
                          ) : null}

                          {itemField.formula2_percentage &&
                          itemField.formula2_percentage > 0 ? (
                            <Controller
                              name={`items.${index}.formula2_value`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  type="number"
                                  label={`${itemField.formula2_name} ${itemField.formula2_percentage}%`}
                                  variant="outlined"
                                  {...field}
                                  error={
                                    !!errors.items?.[index]?.formula2_value
                                  }
                                  helperText={
                                    errors.items?.[index]?.formula2_value
                                      ?.message
                                  }
                                  fullWidth
                                  size="small"
                                  sx={{ minWidth: 170, width: 170 }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Typography fontSize={"14px"}>
                                          {itemField.item_unit}
                                        </Typography>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  disabled={
                                    countData?.data.status === "confirmed"
                                  }
                                />
                              )}
                            />
                          ) : null}
                        </Stack>
                      </Grid>
                      {/* calculations */}
                      <Grid item xs={12} md={12}>
                        {/* divider */}
                        <Box
                          height={"1px"}
                          width={"75%"}
                          bgcolor={"#F0F0F0"}
                          mb={1.3}
                        />
                        <Stack
                          direction={"row"}
                          gap={{ xs: 2, lg: 3 }}
                          flexWrap={"wrap"}
                        >
                          <CalcItem
                            label={t("enteredTotal")}
                            value={`${(countData?.data.status === "confirmed" // To display the confirmed the calculated one as the waste formula may change
                              ? itemField.confirmed_entered_qnty ?? 0
                              : enteredTotalQuantity
                            ).toFixed(3)} ${itemField.item_unit}`}
                          />

                          {countData?.data.status === "sent" ||
                          countData?.data.status === "confirmed" ? (
                            <>
                              <CalcItem
                                label={t("netTransfer")}
                                value={`${itemField.net_transfer ?? 0}`}
                              />

                              <CalcItem
                                label={t("systemQTotal")}
                                value={`${itemField.system_Q?.toFixed(3)} ${
                                  itemField.item_unit
                                }`}
                              />

                              <CalcItem
                                label={t("differenceQnty")}
                                value={`${(
                                  itemField.confirmed_diff_qnty ?? 0
                                ).toFixed(3)} ${itemField.item_unit}`}
                                color={
                                  Number(itemField?.confirmed_diff_qnty) !== 0
                                    ? "error"
                                    : undefined
                                }
                              />

                              <CalcItem
                                label={t("differentCost")}
                                value={`${itemField.diff_cost?.toFixed(3)}`}
                                color={
                                  itemField.diff_cost !== undefined &&
                                  itemField.diff_cost === 0
                                    ? undefined
                                    : "error"
                                }
                              />

                              <CalcItem
                                label={`${t("consumptionQnty")}`}
                                value={`${(
                                  itemField.consumption_quantity ?? 0
                                ).toFixed(3)}`}
                                color={
                                  Number(itemField?.consumption_quantity) < 0
                                    ? "error"
                                    : undefined
                                }
                              />

                              <CalcItem
                                label={`${t("consumption")} %`}
                                value={`${(
                                  itemField.consumption_percentage ?? 0
                                ).toFixed(3)}`}
                                color={
                                  Number(itemField?.consumption_percentage) < 0
                                    ? "error"
                                    : undefined
                                }
                              />

                              <CalcItem
                                label={`${t("consumptionCost")}`}
                                value={`${(
                                  itemField.consumption_cost ?? 0
                                ).toFixed(3)}`}
                              />

                              <CalcItem
                                label={t("total")}
                                value={`${itemField.total?.toFixed(3)}`}
                              />
                            </>
                          ) : null}

                          {/* remove item */}
                          {fields.length > 1 &&
                          countData?.data.status !== "confirmed" ? (
                            <Box
                              display={"flex"}
                              justifyContent={"flex-end"}
                              alignItems={"center"}
                              sx={{ flexGrow: 1 }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  remove(index);
                                  saveDraft();
                                }}
                              >
                                <img
                                  src={DeleteIcon}
                                  alt=""
                                  width={"16px"}
                                  style={{ cursor: "pointer" }}
                                />
                              </IconButton>
                            </Box>
                          ) : null}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
              {countData?.data.status !== "confirmed" ? (
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={
                    itemsData?.data.filter(
                      (item: any) => !selectedItemsIds?.includes(item.id)
                    ) || []
                  }
                  sx={{ width: 255 }}
                  getOptionLabel={(option: any) => option.name}
                  value={selectedItem}
                  onChange={handleItemSelect}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  renderInput={(params: any) => (
                    <TextField {...params} label={t("searchAndAddItem")} />
                  )}
                />
              ) : null}
            </Stack>

            {countData?.data.status !== "confirmed" ? (
              <Stack
                spacing={2}
                direction={"row"}
                mt={3}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="outlined"
                  color="tertiary"
                  onClick={navigateToInventory}
                  sx={{ minWidth: "180px" }}
                >
                  {generalT("back")}
                </Button>
                {(addPermission || confirmedPermission) && (
                  <LoadingButton
                    variant="contained"
                    loading={isPending || isPendingUpdate}
                    onClick={() => {
                      saveDraft();
                      setIsSaving(true);
                    }}
                    sx={{ minWidth: "180px" }}
                    disabled={watch("items")?.length === 0}
                  >
                    {generalT("save")}
                  </LoadingButton>
                )}

                {((countData?.data.status === "draft" && addPermission) ||
                  (countData?.data.status === "sent" &&
                    confirmedPermission)) && (
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    loading={isPending || isPendingUpdate}
                    sx={{ minWidth: "180px" }}
                    disabled={watch("items")?.length === 0}
                  >
                    {generalT(
                      countData?.data.status === "sent" ? "confirm" : "send"
                    )}
                  </LoadingButton>
                )}
              </Stack>
            ) : null}
          </form>
        </>
      )}
    </Stack>
  );
};

export default AddNewStocktaking;
