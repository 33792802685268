import { IconButton, Stack, Tooltip } from "@mui/material";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import { useTranslation } from "react-i18next";
import { useDeleteEventMutation } from "src/api/purchasing/events";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
// import AddEditEventPopup from "../../add-edit-event"
import EventAttachmentsPopup from "../../attachments-popup";
import { oneBranchHasPermission } from "src/utils";
import { PurchasingPermissions } from "src/constants/permissions";

interface IAction {
  row: any;
  refetch: Function;
}

const TableActions = (props: IAction) => {
  const { t } = useTranslation("general");
  const { row, refetch } = props;
  const { id, reference } = row;
  const [openDelete, setOpenDelete] = useState(false);
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);

  const [notifications, setNotifications] = useRecoilState(notificationsState);

  // APIS
  const { mutate, isPending, status, error } = useDeleteEventMutation();

  //permissions
  const deletePermission = oneBranchHasPermission(
    PurchasingPermissions.deleteEvent
  );

  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleOpenAttachmentsModal = () => setOpenAttachmentsModal(true);
  const handleCloseAttachmentsModal = () => setOpenAttachmentsModal(false);

  const handleDelete = () => {
    mutate(id);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleCloseDelete();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} justifyContent={"flex-end"}>
      <Tooltip title={t("attachments")}>
        <IconButton onClick={handleOpenAttachmentsModal}>
          <img src={DownloadIcon} alt="" />
        </IconButton>
      </Tooltip>

      {/* <Tooltip title={t("edit")}>
        <IconButton onClick={handleOpenEdit}>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip> */}

      {deletePermission && (
        <Tooltip title={t("delete")}>
          <IconButton onClick={handleOpenDelete}>
            <img src={DeleteIcon} alt="" />
          </IconButton>
        </Tooltip>
      )}

      {/* <Tooltip title={t("details")}>
        <IconButton>
          <VisibilityOutlinedIcon fontSize="small" />
        </IconButton>
      </Tooltip> */}

      {/* confirm delete popup */}
      <ConfirmPopup
        open={openDelete}
        item={reference}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
      {/* edit popup */}
      {
      // openEdit && (
      //   <AddEditEventPopup
      //     open={openEdit}
      //     handleClose={handleCloseEdit}
      //     eventToEdit={row}
      //     refetch={refetch}
      //   />
      // )
      
      }
      {/* attachments popup */}
      {openAttachmentsModal && (
        <EventAttachmentsPopup
          open={openAttachmentsModal}
          handleClose={handleCloseAttachmentsModal}
          rowId={row.id}
        />
      )}
    </Stack>
  );
};
export default TableActions;
