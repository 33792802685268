import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useGetAdjustmentsList } from "src/api/inventory/adjustments";
import { useNavigate } from "react-router-dom";
import ImportAdjustmentsPopup from "./import-adjustments-popup";
import { InventoryPermissions } from "src/constants/permissions";
import { oneBranchHasPermission } from "src/utils";

const Adjustments = () => {
  const { t } = useTranslation("purchasing");
  const { t: inventoryT } = useTranslation("inventory");
  const { t: generalT } = useTranslation("general");

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [openImport, setOpenImport] = useState(false);
  const navigate = useNavigate();

  const navigateToAddPage = () => {
    navigate("/inventory/add-adjustments");
  };

  const navigateToViewAdjustments = (id: any) => {
    navigate(`/adjustments/${id}/view`);
  };

  const HeadText = [
    t("reference"),
    t("branch"),
    inventoryT("date"),
    t("createdBy"),
    t("createdAt"),
    t("actions"),
  ];

  // APIS
  const { data, isFetching, refetch } = useGetAdjustmentsList({
    page: pageNumber + 1,
  });

  const addPermission = oneBranchHasPermission(
    InventoryPermissions.addUnitCostAdjustment
  );

  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Stack
          direction={"row"}
          gap={2}
          flexWrap={"wrap"}
          mt={{ xs: 2, md: 0 }}
        >
          {addPermission && (
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpenImport}
              >
                {inventoryT("importAdjustments")}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={navigateToAddPage}
                size="small"
              >
                {inventoryT("addAdjustments")}
              </Button>
            </>
          )}
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell
                  key={item}
                  align={"center"}
                  sx={{
                    bgcolor: "#F9FAFB",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    color={"#475467"}
                    lineHeight={"18px"}
                    fontWeight={500}
                  >
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching || !data ? (
            <TableLoadingSkeleton rowsLength={15} cellsLength={5} />
          ) : (
            <TableBody>
              {data.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.branch?.name}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="center">
                    <Tooltip title={generalT("details")}>
                      <IconButton
                        onClick={() => navigateToViewAdjustments(row.id)}
                      >
                        <VisibilityOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      {openImport ? (
        <ImportAdjustmentsPopup
          open={openImport}
          handleClose={handleCloseImport}
        />
      ) : null}
    </Stack>
  );
};
export default Adjustments;
