import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import handshakeIcon from "src/assets/svg-icons/aggregators/handshake.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RightArrow from "src/assets/svg-icons/aggregators/right_arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useAddAggCostAndFeesMutation,
  useGetAggJourneys,
  useGetAggregatorCostAndFeeById,
  useGetAggregatorsList,
  useUpdateAggCostAndFeesMutation,
} from "src/api/aggreagators";
import { useGetBranches, useGetJourneys } from "src/api/generic";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { specificBranchHasPermission } from "src/utils";

const schema = yup.object().shape({
  branch_id: yup.number().required("Required"),
  journeys: yup
    .array()
    .of(yup.number().required("Required"))
    .required("Required")
    .min(1, "Required"),
  aggregator_id: yup.string().required("Required"),
  bank_fee: yup.number().typeError("Required").required("Required"),
  other_fee: yup.number().typeError("Required").required("Required"),
  compensation: yup.number().typeError("Required").required("Required"),
  payment: yup.string().required("Required"),
});

export interface FormInputs extends yup.InferType<typeof schema> {}

const AddEditAggregatorFees = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation("aggregators");
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [mode, setMode] = useState<"add" | "edit">("add");
  const [selectedJourneys, setSelectedJourneys] = useState<any[]>([]);

  const [aggCalcs, setAggCalcs] = useState({
    basicAggFees: 0,
    deliverFees: 0,
    feeTax: 0,
    deliveryFeeTax: 0,
  });

  useEffect(() => {
    if (params.id) {
      setMode("edit");
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: {
      journeys: [],
    },
  });

  // APIS
  const { data: branchesData } = useGetBranches();
  const { mutate, status, error, isPending } = useAddAggCostAndFeesMutation();
  const {
    mutate: mutateUpdate,
    error: errorUpdate,
    status: statusUpdate,
    isPending: isPendingUpdate,
  } = useUpdateAggCostAndFeesMutation();
  const {
    data,
    status: getAggFeeByIdStatus,
    refetch,
  } = useGetAggregatorCostAndFeeById({
    enabled: !!params.id,
    id: params.id as string,
  });
  const { data: journeysData } = useGetAggJourneys({
    branch_id: watch("branch_id"),
    aggregator_id: watch("aggregator_id"),
  });
  const { data: allJourneysData } = useGetJourneys({
    enabled: !!watch("branch_id"),
    branches: [watch("branch_id")],
  });
  const { data: aggregatorsData } = useGetAggregatorsList({
    enabled: !!watch("branch_id"),
    branches: [`${watch("branch_id")}`],
  });

  const selectedAgg = aggregatorsData?.data.find(
    (agg: any) => agg.id === +watch("aggregator_id")
  ) || { related_payment_methods: [] };

  // permissions
  const branchesHavePermission =
    branchesData?.data.filter((branch) =>
      specificBranchHasPermission("add_aggregator_cost_and_fee", branch.id)
    ) || [];

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (mode === "add") {
      const { branch_id, aggregator_id, payment, ...rest } = data;
      mutate({
        ...rest,
        branch_id: Number(branch_id),
        aggregator_id: Number(aggregator_id),
        aggregator_fee: aggCalcs.basicAggFees,
        aggregator_fee_tax: aggCalcs.feeTax,
        delivery_fee: aggCalcs.deliverFees,
        delivery_fee_tax: aggCalcs.deliveryFeeTax,
        payment_methods: [+payment],
      });
    } else {
      mutateUpdate({
        id: params.id,
        bank_fee: data.bank_fee,
        other_fee: data.other_fee,
        compensation: data.compensation,
      });
    }
  };

  const navigateToAggregators = () => {
    navigate("/aggregators/fees");
  };

  useEffect(() => {
    if (journeysData && mode === "add") {
      const journeysList: any[] = [];
      watch("journeys").forEach((journeyId) => {
        const selectedJourney = journeysData?.data.journeys.find(
          (journey: any) => journey.id === journeyId
        );
        journeysList.push(selectedJourney);
      });
      setSelectedJourneys(journeysList);
    }
  }, [watch("journeys")]);

  useEffect(() => {
    if (mode === "add") {
      let aggFee = 0;
      let deliveryFee = 0;
      let totalFeeTax = 0;
      let totalDeliveryFeeTax = 0;
      selectedJourneys.forEach((journey) => {
        journey.aggregator_sales.map((aggSale: any) => {
          if (aggSale.aggregator.id === +watch("aggregator_id")) {
            aggFee += aggSale.aggregator_fee;
            deliveryFee += aggSale.delivery_fee;
            totalFeeTax += aggSale.aggregator_fee_tax;
            totalDeliveryFeeTax += aggSale.delivery_fee_tax;
          }
        });
      });
      setAggCalcs({
        basicAggFees: aggFee,
        deliverFees: deliveryFee,
        feeTax: totalFeeTax,
        deliveryFeeTax: totalDeliveryFeeTax,
      });
    }
  }, [selectedJourneys]);

  //Get agg fee by Id success and error handling
  useEffect(() => {
    if (getAggFeeByIdStatus === "success") {
      reset({
        branch_id: data.data.branch.id,
        journeys: [data.data.journeys],
        aggregator_id: data.data.aggregator.id,
        bank_fee: data.data.bank_fee,
        other_fee: data.data.other_fee,
        compensation: data.data.compensation,
        payment: data.data.payments[0]?.payment_method?.id,
      });
      setAggCalcs({
        basicAggFees: 5,
        deliverFees: 5,
        feeTax: 5,
        deliveryFeeTax: 5,
      });
    }
  }, [getAggFeeByIdStatus, journeysData, aggregatorsData]);

  useEffect(() => {
    setValue("aggregator_id", "");
  }, [watch("branch_id")]);

  useEffect(() => {
    setValue("journeys", []);
    setValue("payment", "");
  }, [watch("aggregator_id")]);

  //Add agg cost/fee success and error handling
  useEffect(() => {
    if (status === "success") {
      navigateToAggregators();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors?.[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update agg cost/fee success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: t("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: errorUpdate?.data.errors?.[0].value || t("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  console.log({ selectedJourneys, aggCalcs, errors });

  const selectedJourneysToViewInViewMode = data?.data.journeys
    .map((journeyId: number) => {
      const journey = allJourneysData?.data.journeys?.find(
        (journey: any) => journey.id === journeyId
      );
      return `${journey?.start_date || ""} ${generalT("to")} ${
        journey?.end_date || ""
      }`;
    })
    .join(", ");

  return (
    <Stack height={"100%"}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Stack height={"100%"} justifyContent={"space-between"}>
          <Box p={{ xs: 2, sm: 4 }}>
            <Stack direction={"row"} spacing={"12px"} mb={3}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                width={"60px"}
                height={"60px"}
                bgcolor={"#D0D5DD"}
                borderRadius={"16px"}
              >
                <img src={handshakeIcon} width={"32px"} alt="" />
              </Box>
              <Box>
                <Typography fontSize={"32px"} lineHeight={"38.4px"}>
                  {t("aggs")}
                </Typography>
                {mode === "edit" && (
                  <Typography
                    fontSize={"20px"}
                    lineHeight={"24px"}
                    color={"#98A2B3"}
                  >
                    {t("lastUpdated")}: {data?.data.updated_at}
                  </Typography>
                )}
              </Box>
            </Stack>
            {/* divider */}
            <Box bgcolor={"#F2F4F7"} width={"100%"} />
            <Stack spacing={1} direction={"row"} mb={3} alignItems={"center"}>
              <Typography
                color={"#475467"}
                lineHeight={"19.2px"}
                onClick={navigateToAggregators}
                sx={{ cursor: "pointer" }}
              >
                {t("aggs")}
              </Typography>
              <img src={RightArrow} alt="" />
              <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
                <Typography
                  color={"#344054"}
                  fontWeight={600}
                  lineHeight={"19.2px"}
                >
                  {mode === "add" ? t("addNew") : t("edit")} {t("aggsFeesCost")}
                </Typography>
              </Box>
            </Stack>
            {!data && mode === "edit" ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                minHeight={"350px"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Stack spacing={1.5} alignItems={"center"} width={"100%"}>
                {/*  Branch Select */}
                <Stack
                  sx={{
                    minWidth: { xs: "100%", md: 872 },
                    maxWidth: { xs: "100%", md: 872 },
                  }}
                  gap={1.5}
                >
                  <FormControl
                    fullWidth
                    size="small"
                    error={!!errors.branch_id}
                    disabled={mode === "edit"}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {t("branchLocation")}
                    </InputLabel>
                    <Controller
                      name="branch_id"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          key={field.value}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t("branchLocation")}
                        >
                          {branchesHavePermission?.map(({ id, name }) => {
                            return (
                              <MenuItem key={id} value={`${id}`}>
                                {name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      )}
                    />
                    <FormHelperText id="my-helper-text">
                      {errors.branch_id?.message}
                    </FormHelperText>
                  </FormControl>

                  {/* Aggregators Select  */}
                  <Box
                    sx={
                      {
                        // display: mode === "add" ? "block" : "none",
                      }
                    }
                  >
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.aggregator_id}
                      disabled={mode === "edit"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {t("agg")}
                      </InputLabel>
                      <Controller
                        name="aggregator_id"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            key={field.value}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("agg")}
                          >
                            {aggregatorsData?.data?.map(({ id, name }: any) => {
                              return (
                                <MenuItem key={id} value={`${id}`}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                            {aggregatorsData?.data.length === 0 &&
                              watch("journeys").length > 0 && (
                                <Typography
                                  align="center"
                                  color={"textSecondary"}
                                >
                                  {generalT("noData")}
                                </Typography>
                              )}
                          </Select>
                        )}
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.aggregator_id?.message}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  {/* Journey Select  */}
                  {mode === "add" ? (
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.journeys}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">
                        {t("selectJourneys")}
                      </InputLabel>
                      <Controller
                        name={`journeys`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            input={
                              <OutlinedInput label={t("selectJourneys")} />
                            }
                            renderValue={(selected) =>
                              selected
                                .map((selectedId) => {
                                  const journey =
                                    journeysData?.data.journeys?.find(
                                      (journey: any) =>
                                        journey.id === +selectedId
                                    );
                                  return `${
                                    journey?.start_date || ""
                                  } ${generalT("to")} ${
                                    journey?.end_date || ""
                                  }`;
                                })
                                .join(", ")
                            }
                          >
                            {journeysData?.data.journeys.map((journey: any) => (
                              <MenuItem key={journey.id} value={journey.id}>
                                <Checkbox
                                  checked={
                                    field.value &&
                                    field.value.indexOf(journey.id) > -1
                                  }
                                />
                                {journey?.start_date} {generalT("to")}{" "}
                                {journey?.end_date}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.journeys?.message}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    <TextField
                      fullWidth
                      disabled
                      value={selectedJourneysToViewInViewMode || ""}
                      label={t("selectJourneys")}
                      multiline
                    />
                  )}

                  {/* Bank Fee */}
                  <Controller
                    name="bank_fee"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label={t("bankFeeEx")}
                        {...field}
                        error={!!errors.bank_fee}
                        helperText={errors.bank_fee?.message}
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: field.value !== undefined,
                        }}
                        disabled={mode === "edit"}
                      />
                    )}
                  />
                  {/* Other Fee */}
                  <Controller
                    name="other_fee"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label={t("otherFeesEx")}
                        {...field}
                        error={!!errors.other_fee}
                        helperText={errors.other_fee?.message}
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: field.value !== undefined,
                        }}
                        disabled={mode === "edit"}
                      />
                    )}
                  />
                  {/* Compensation */}
                  <Controller
                    name="compensation"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="number"
                        label={t("compensation")}
                        {...field}
                        error={!!errors.compensation}
                        helperText={errors.compensation?.message}
                        fullWidth
                        size="small"
                        InputLabelProps={{
                          shrink: field.value !== undefined,
                        }}
                        disabled={mode === "edit"}
                      />
                    )}
                  />
                  {/* Agg Fee */}
                  <TextField
                    type="number"
                    label={t("basicAggregatorFees")}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      mode === "add"
                        ? aggCalcs.basicAggFees
                        : data?.data.aggregator_fee
                    }
                    disabled
                  />
                  {/* Delivery Fee */}
                  <TextField
                    type="number"
                    label={t("deliveryCost")}
                    fullWidth
                    size="small"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      mode === "add"
                        ? aggCalcs.deliverFees
                        : data?.data.delivery_fee
                    }
                    disabled
                  />
                  {/* Agg payment */}
                  {mode === "add" ? (
                    <FormControl
                      fullWidth
                      size="small"
                      error={!!errors.payment}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {t("selectAggregatorPayment")}
                      </InputLabel>
                      <Controller
                        name="payment"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            key={field.value}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label={t("selectAggregatorPayment")}
                          >
                            {selectedAgg?.related_payment_methods
                              .filter(
                                (payment: any) =>
                                  !payment.payment_method.is_cash
                              )
                              .map(({ payment_method }: any) => {
                                return (
                                  <MenuItem
                                    key={payment_method.id}
                                    value={`${payment_method.id}`}
                                  >
                                    {payment_method.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        )}
                      />
                      <FormHelperText id="my-helper-text">
                        {errors.payment?.message}
                      </FormHelperText>
                    </FormControl>
                  ) : (
                    <FormControl
                      fullWidth
                      size="small"
                      disabled={mode === "edit"}
                    >
                      <InputLabel id="demo-simple-select-label">
                        {t("selectAggregatorPayment")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={t("selectAggregatorPayment")}
                        value={data?.data.payments[0]?.payment_method?.id}
                      >
                        {selectedAgg?.related_payment_methods
                          .filter(
                            (payment: any) => !payment.payment_method.is_cash
                          )
                          .map(({ payment_method }: any) => {
                            return (
                              <MenuItem
                                key={payment_method.id}
                                value={`${payment_method.id}`}
                              >
                                {payment_method.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  )}
                </Stack>
              </Stack>
            )}
          </Box>

          {mode === "add" && (
            <Box
              p={{ xs: " 24px 16px", sm: 4 }}
              bgcolor={"#FCFCFD"}
              display={"flex"}
              justifyContent={"center"}
              boxShadow={"0 -8px 50px -5px rgba(50, 50, 50, 0.1)"}
            >
              <Stack
                direction={"row"}
                sx={{ minWidth: { xs: "100%", md: 872 } }}
                justifyContent={"space-between"}
              >
                <Button
                  variant="outlined"
                  color="tertiary"
                  onClick={navigateToAggregators}
                >
                  {t("cancel")}
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  endIcon={<KeyboardArrowRightIcon />}
                  loading={isPending || isPendingUpdate}
                >
                  {t("submit")}
                </LoadingButton>
              </Stack>
            </Box>
          )}
        </Stack>
      </form>
    </Stack>
  );
};

export default AddEditAggregatorFees;
