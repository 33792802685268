import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  start_date?: string | null;
  end_date?: string | null;
}

export const useGetPurchasingStatistics = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-purchase-summary-statistics",
      params?.start_date ?? "",
      params?.end_date ?? "",
    ],
    queryFn: async () => {
      return apiClient.get(`/api/purchases/purchase-statistics`, {
        params: params || {},
      });
    },
    enabled: !!params?.start_date && !!params.end_date,
  });

export const useGetPurchasingItemsReport = (params?: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-purchase-summary-items-report",
      params?.start_date ?? "",
      params?.end_date ?? "",
    ],
    queryFn: async () => {
      return apiClient.get(`/api/purchases/purchase-items-report`, {
        params: params || {},
      });
    },
    enabled: !!params?.start_date && !!params.end_date,
  });
