import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import UnitsTable from "./units";
import DeletedItemTable from "./deleted-item";
import AllTable from "./all";
import Adjustments from "./adjustments";
import { oneBranchHasPermission } from "src/utils";
import { InventoryPermissions } from "src/constants/permissions";
import NoAccessPage from "src/pages/no-access";

interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: Readonly<TabProps>) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }} height={"40px"}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ItemsTabs = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation("purchasing");
  const { t: inventoryT } = useTranslation("inventory");

  const labels = [
    t("items"),
    t("units"),
    inventoryT("unitAdjustments"),
    t("deletedItems"),
  ];

  // permissions
  const viewItems = oneBranchHasPermission(InventoryPermissions.viewItems);
  const viewUCAdjustments = oneBranchHasPermission(
    InventoryPermissions.viewUnitCostAdjustments
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs value={value} onChange={handleChange}>
        {labels.map((label, index) => {
          return (
            <Tab
              disableRipple
              key={`${index}_${label}`}
              label={label}
              {...a11yProps(index)}
            />
          );
        })}
      </Tabs>

      <Box mt={{ xs: "-20px", md: "-65px" }}>
        <CustomTabPanel value={value} index={0}>
          {viewItems ? <AllTable /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {viewItems ? <UnitsTable /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {viewUCAdjustments ? <Adjustments /> : <NoAccessPage />}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          {viewItems ? <DeletedItemTable /> : <NoAccessPage />}
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default ItemsTabs;
