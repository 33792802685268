import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetMatchingAggsList } from "src/api/matching/aggregators";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import FilterDrawer from "../filters";
import moment from "moment";
import DateRangeInput from "src/shared/components/date-range";

const AggregatorTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("matching");
  const { t: generalT } = useTranslation("general");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [aggregator, setAggregator] = useState("jahez");
  const [total, setTotal] = useState<number>(0);
  const [reference, setReference] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [type, setType] = useState("all");
  const [openFilter, setOpenFilter] = useState(false);
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });

  const tableHeadText = [
    t("reference"),
    t("aggregator"),
    generalT("branch"),
    t("period"),
    t("timePeriod"),
    t("result"),
    t("total"),
    t("totalDifferent"),
    t("dateTransaction"),
    t("createdBy"),
    t("actions"),
  ];

  // APIS
  const { data, refetch, isLoading } = useGetMatchingAggsList({
    page: pageNumber + 1,
    ...(!!reference && { reference_number: reference }),
    ...(!!orderRef && { order_reference_number: orderRef }),
    ...(!!type &&
      type !== "all" && { is_matched: type === "matched" ? "1" : "0" }),
    ...(!!dateRange.startDate && { start_date: dateRange.startDate }),
    ...(!!dateRange.endDate && { end_date: dateRange.endDate }),
  });

  const emptyDataArr = data?.data.length === 0;

  const handleChangeAggregator = (event: SelectChangeEvent) => {
    setAggregator(event.target.value);
  };

  const navigateToAggregatorTransaction = () => {
    navigate("/matching/add-aggregator-transactions");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  useEffect(() => {
    if (data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Box>
      <Stack
        sx={{ flexDirection: { xs: "column", md: "row" } }}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        gap={2}
        alignItems={"center"}
        mb={1.5}
      >
        {/*  Aggregator Select  */}
        <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth size="small">
            <InputLabel>{t("selectAgg")}</InputLabel>
            <Select
              value={aggregator}
              label={t("selectAgg")}
              onChange={handleChangeAggregator}
            >
              <MenuItem value={"jahez"}>{t("jahez")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Box sx={{ minWidth: 207 }}>
            <DateRangeInput
              startDate={
                dateRange.startDate ? moment(dateRange.startDate) : null
              }
              startDateId={`your_unique_start_date_id`}
              endDate={dateRange.endDate ? moment(dateRange.endDate) : null}
              endDateId={`your_unique_end_date_id`} // string;
              onDatesChange={(arg: {
                startDate: moment.Moment | null;
                endDate: moment.Moment | null;
              }) => {
                setDateRange({
                  startDate: arg.startDate?.format("yyyy-MM-DD") as string,
                  endDate: arg.endDate?.format("yyyy-MM-DD") as string,
                });
              }}
            />
          </Box>
          <Button
            color="tertiary"
            variant="outlined"
            startIcon={<img src={FilterIcon} alt="" />}
            onClick={handleOpenFilter}
          >
            {t("filter")}
          </Button>
          <Button
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
          >
            {t("download")}
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={navigateToAggregatorTransaction}
          >
            {t("addAggTransactions")}
          </Button>
        </Stack>
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={11} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.reference}</TableCell>
                  <TableCell align="center">{row.aggregator}</TableCell>
                  <TableCell align="center">{row.branch?.name}</TableCell>
                  <TableCell align="center">
                    {row.start_date} <br /> {row.end_date}
                  </TableCell>
                  <TableCell align="center">
                    {row.start_time} <br /> {row.end_time}
                  </TableCell>
                  <TableCell align="center">
                    <Box>
                      <Chip
                        label={t(row.is_matched ? "matched" : "unmatched")}
                        color={row.is_matched ? "success" : "error"}
                        variant="outlined"
                        sx={{ height: "22px" }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell align="center">{row.total.toFixed(2)}</TableCell>
                  <TableCell align="center">{row.total_difference}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      <FilterDrawer
        open={openFilter}
        reference={reference}
        setReference={setReference}
        orderRef={orderRef}
        setOrderRef={setOrderRef}
        type={type}
        setType={setType}
        handleClose={handleCloseFilter}
      />
    </Box>
  );
};
export default AggregatorTable;
