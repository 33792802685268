import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import EditIcon from "src/assets/svg-icons/aggregators/edit.svg";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import { useEffect, useState } from "react";
import { ConfirmPopup } from "src/shared/components/confirm-popup";
import { useDeleteOCTransactionMutation } from "src/api/operation-cost/transactions";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import AddEditTransaction from "../../add-edit-transaction-popup";
import AttachmentsPopup from "../../attachments-popup";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import AddPaymentPopup from "../../add-payment-popup";
import { useTranslation } from "react-i18next";
import { specificBranchHasPermission } from "src/utils";
import { OCPermissions } from "src/constants/permissions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";

interface IAction {
  row: any;
  refetch: Function;
  isDeprecationInvoice: boolean;
}

const TableActions = (props: IAction) => {
  const navigate = useNavigate();
  const { row, refetch, isDeprecationInvoice } = props;
  const { id, reference } = row;
  const { t: generalT } = useTranslation("general");
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [open, setOpen] = useState(false);
  const [openPay, setOpenPay] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAttachmentsModal, setOpenAttachmentsModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  //permissions
  const editPermission = specificBranchHasPermission(
    OCPermissions.editOC,
    row.branch_id
  );
  const deletePermission = specificBranchHasPermission(
    OCPermissions.deleteOC,
    row.branch_id
  );
  const payPermission = specificBranchHasPermission(
    OCPermissions.payOC,
    row.branch_id
  );

  // APIS
  const { mutate, isPending, status, error } = useDeleteOCTransactionMutation();

  const handleOpenDelete = () => setOpen(true);
  const handleCloseDelete = () => setOpen(false);

  const handleOpenPay = () => setOpenPay(true);
  const handleClosePay = () => setOpenPay(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  const handleOpenAttachmentsModal = () => setOpenAttachmentsModal(true);

  const handleCloseAttachmentsModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpenAttachmentsModal(false);
  };

  const handleDelete = () => {
    mutate(id);
  };

  const navigateToTransDetails = () => {
    navigate(`/operation/transaction/${id}`);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (status === "success") {
      refetch();
      handleCloseDelete();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("deletedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Stack direction={"row"} spacing={"12px"} width={"100%"}>
      <IconButton size="small" onClick={(e) => handleClick(e)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleOpenAttachmentsModal} sx={{ gap: 1 }}>
          <img src={DownloadIcon} alt="" />
          <Typography
            color={"#1D2939"}
            fontSize={"16px"}
            textTransform={"capitalize"}
          >
            {generalT("attachments")}
          </Typography>
        </MenuItem>
        {editPermission && (
          <MenuItem onClick={handleOpenEdit} sx={{ gap: 1 }}>
            <img src={EditIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              {generalT("edit")}
            </Typography>
          </MenuItem>
        )}
        {deletePermission && (
          <MenuItem onClick={handleOpenDelete} sx={{ gap: 1 }}>
            <img src={DeleteIcon} alt="" />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              {generalT("delete")}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={navigateToTransDetails} sx={{ gap: 1 }}>
          <VisibilityOutlinedIcon sx={{ fontSize: "16px" }} />
          <Typography
            color={"#1D2939"}
            fontSize={"16px"}
            textTransform={"capitalize"}
          >
            {generalT("details")}
          </Typography>
        </MenuItem>

        {payPermission && row?.status !== "Paid" && !isDeprecationInvoice && (
          <MenuItem onClick={handleOpenPay} sx={{ gap: 1 }}>
            <PaidOutlinedIcon sx={{ fontSize: "16px" }} />
            <Typography
              color={"#1D2939"}
              fontSize={"16px"}
              textTransform={"capitalize"}
            >
              {`${generalT("pay")}!`}
            </Typography>
          </MenuItem>
        )}
      </Menu>

      {/* payment popup */}
      {openPay && (
        <AddPaymentPopup
          open={openPay}
          handleClose={handleClosePay}
          refetch={refetch}
          id={id}
          row={row}
        />
      )}
      {/* confirm delete popup */}
      <ConfirmPopup
        open={open}
        item={reference}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        isPending={isPending}
      />
      {/* edit popup */}
      {openEdit && (
        <AddEditTransaction
          open={openEdit}
          handleClose={handleCloseEdit}
          row={row}
          refetch={refetch}
        />
      )}
      {/* attachments popup */}
      {openAttachmentsModal && (
        <AttachmentsPopup
          open={openAttachmentsModal}
          handleClose={handleCloseAttachmentsModal}
          rowId={row.id}
        />
      )}
    </Stack>
  );
};
export default TableActions;
