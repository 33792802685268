import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  aggregatorId?: number;
  reference_number?: string;
  order_reference_number?: string;
  is_matched?: string;
  start_date?: string | number;
  end_date?: string | number;
}

export const useGetMatchingAggsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-matching-aggs-list",
      params.page,
      params.aggregatorId,
      params.reference_number,
      params.order_reference_number,
      params.is_matched,
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/matchings`, { params });
    },
    enabled:
      (!!params.start_date && !!params.end_date) ||
      (!params.start_date && !params.end_date),
  });

export const useImportAggsMatchingMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/matchings`, data);
    },
  });

export const useDeleteMatchingMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/matchings/${id}`);
    },
  });

interface IMatchOrdersParams {
  page?: number;
  matching_id: number;
  is_matched?: number; // 0 | 1
}

export const useGetMatchingAggsOrdersList = (params: IMatchOrdersParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-matching-orders-list", params.page],
    queryFn: async () => {
      return apiClient.get(`/api/orderMatchings`, { params });
    },
  });

// for search
export const useGetAggsOrderMatches = (params: { matching_id: number }) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-agg-order-matches", params.matching_id],
    queryFn: async () => {
      return apiClient.get(`/api/orders`, { params });
    },
  });

interface IBody {
  AggOrderId: number;
  diff: number;
  note: string;
  orderIds: number[];
}

export const useUpdateAggMatchingMutation = () =>
  useMutation<void, APIErrorResponse, IBody>({
    mutationFn: async (data) => {
      const { AggOrderId, ...rest } = data;
      return apiClient.put(`/api/orderMatchings/${AggOrderId}`, rest);
    },
  });
