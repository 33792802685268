import {
  Typography,
  Box,
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Checkbox,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Divider,
  Chip,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FormData } from "../../constants";
import { useEffect, useState } from "react";
import { IPayment } from "src/types/add-sales";
import moment from "moment";
import InfoBox from "src/shared/components/info-box";
import YoutubeViewerBox from "../../youtube-viewer-box";
import { useTranslation } from "react-i18next";
import { formatNumber } from "src/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AggregatorDetails = ({
  control,
  errors,
  watch,
  index,
  journeyData,
  isOnboarding,
  journeyTaxPercentage,
}: {
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  index: number;
  journeyData: any;
  isOnboarding: boolean;
  journeyTaxPercentage: number;
}) => {
  const [aggregatorSales, setAggregatorSales] = useState(0);
  const [aggregatorOrderQuantity, setAggregatorOrderQuantity] = useState(0);
  const journeyTaxDivider = 1 + journeyTaxPercentage;
  const journeyType = watch("journey_type");
  const { t } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");

  useEffect(() => {
    if (watch("journey_type") === "foodics") {
      // Filter transactions based on target IDs
      const filteredPayments = journeyData?.payments.filter(
        (payment: IPayment) =>
          watch(`aggregators.${index}.payments`)?.includes(
            `${payment.paymentMethod.id}`
          )
      );
      // Calculate the accumulated total
      const accumulatedData = filteredPayments.reduce(
        (acc: { amount: number; quantity: number }, payment: IPayment) => {
          acc.amount += payment.total;
          acc.quantity += payment.quantity;
          return acc;
        },
        { amount: 0, quantity: 0 }
      );
      setAggregatorSales(accumulatedData.amount);
      setAggregatorOrderQuantity(accumulatedData.quantity);
    } else {
      setAggregatorSales(
        +(watch("aggregators")[index]?.net_sales_with_tax || 0)
      );
      setAggregatorOrderQuantity(
        +(watch("aggregators")[index]?.order_quantity || 0)
      );
    }
  }, [
    watch(`aggregators.${index}.payments`),
    watch("aggregators")[index].net_sales_with_tax,
    watch("aggregators")[index].order_quantity,
  ]);

  const startDate =
    journeyType === "foodics"
      ? journeyData?.start_date
      : watch("dateRange.start_date");
  const endDate =
    journeyType === "foodics"
      ? journeyData?.end_date
      : watch("dateRange.end_date");

  const otherAggsPaymentsIds: any[] = [];
  watch("aggregators").forEach((agg, i) => {
    if (i !== index) {
      otherAggsPaymentsIds.push(...(agg.payments as string[]));
    }
  });

  const availablePaymentsOptions = journeyData?.payments.filter(
    (pay: any) => !otherAggsPaymentsIds.includes(`${pay.paymentMethod.id}`)
  );

  return (
    <div key={`_${index}`}>
      <Typography fontSize={"26px"} fontWeight={600}>
        {t("aggsDetails")}
      </Typography>
      <Typography color={"textSecondary"}>
        {t("fillTheRequiredInfo")}
      </Typography>
      <YoutubeViewerBox videoId="oyT2fpWX8vQ" />
      <form>
        <Stack gap={1.5} alignItems={"flex-start"}>
          {/* name */}
          <Controller
            name={`aggregators.${index}.aggregator_name`}
            control={control}
            render={({ field }) => (
              <TextField
                label={generalT("aggName")}
                {...field}
                error={!!errors.aggregators?.[index]?.aggregator_name}
                helperText={
                  errors.aggregators?.[index]?.aggregator_name?.message
                }
                fullWidth
                size="small"
                disabled={!isOnboarding}
              />
            )}
          />
          {/* fee */}
          <Controller
            name={`aggregators.${index}.aggregator_fee`}
            control={control}
            render={({ field }) => (
              <TextField
                label={`${generalT("aggFee")} %`}
                type="number"
                {...field}
                error={!!errors.aggregators?.[index]?.aggregator_fee}
                helperText={
                  errors.aggregators?.[index]?.aggregator_fee?.message
                }
                fullWidth
                size="small"
                disabled={!isOnboarding}
              />
            )}
          />
          {!isOnboarding && (
            <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
              <Typography>{generalT("relatedPayments")}</Typography>
              {watch("aggregators")[index].payments_Names?.map((name) => {
                return (
                  <Chip
                    key={name}
                    label={name}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                );
              })}
            </Stack>
          )}
          {/* related payments */}
          {journeyType === "foodics" && isOnboarding && (
            <FormControl
              fullWidth
              size="small"
              error={
                errors.aggregators && !!errors.aggregators[index]?.payments
              }
              disabled={!isOnboarding}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                {generalT("selectPayments")}
              </InputLabel>
              <Controller
                name={`aggregators.${index}.payments`}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    input={<OutlinedInput label={generalT("selectPayments")} />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (selectedId: string) =>
                            journeyData?.payments.find(
                              (payment: any) =>
                                payment.paymentMethod.id === +selectedId
                            ).paymentMethod?.name
                        )
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {availablePaymentsOptions.map((payment: any) => (
                      <MenuItem
                        key={payment.paymentMethod.id}
                        value={`${payment.paymentMethod.id}`}
                      >
                        <Checkbox
                          checked={
                            field.value &&
                            field.value.indexOf(`${payment.paymentMethod.id}`) >
                              -1
                          }
                        />
                        {payment.paymentMethod.name}
                      </MenuItem>
                    ))}

                    {availablePaymentsOptions.length === 0 && (
                      <Typography align="center" color={"textSecondary"}>
                        {generalT("noData")}
                      </Typography>
                    )}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.aggregators?.[index]?.payments?.message}
              </FormHelperText>
            </FormControl>
          )}

          {/* Duration */}
          <InfoBox
            text={`${t("aggValuesShould")}
              ${moment(startDate).format("MM-DD-YYYY")} ${
              startDate !== endDate
                ? `${generalT("to")} ${moment(endDate).format("MM-DD-YYYY")}`
                : ""
            } 
              `}
          />
          {/* For manual only */}
          {journeyType === "manual" && (
            <>
              <Controller
                name={`aggregators.${index}.net_sales_with_tax`}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={t("salesIncludeTax")}
                    type="number"
                    {...field}
                    error={!!errors.aggregators?.[index]?.net_sales_with_tax}
                    helperText={
                      errors.aggregators?.[index]?.net_sales_with_tax?.message
                    }
                    fullWidth
                    size="small"
                  />
                )}
              />
              <Controller
                name={`aggregators.${index}.order_quantity`}
                control={control}
                render={({ field }) => (
                  <TextField
                    label={generalT("orderQuantity")}
                    type="number"
                    {...field}
                    error={!!errors.aggregators?.[index]?.order_quantity}
                    helperText={
                      errors.aggregators?.[index]?.order_quantity?.message
                    }
                    fullWidth
                    size="small"
                  />
                )}
              />
            </>
          )}
          {/* Type of Delivery */}
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={{ xs: 1, sm: 1, md: 3 }}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Typography color={"textSecondary"}>{t("addAggOffer")}</Typography>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.has_aggregators}
              sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
            >
              <Controller
                name={`aggregators.${index}.delivery_type`}
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="order"
                      control={
                        <Radio
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={t("costByOrder")}
                      sx={{ color: "#98A2B3" }}
                    />
                    <FormControlLabel
                      value="amount"
                      control={
                        <Radio
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={t("costByAmount")}
                      sx={{ color: "#98A2B3" }}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Stack>
          <FormHelperText error id="my-helper-text" sx={{ mt: -3, px: 2 }}>
            {errors.aggregators && !!errors.aggregators[index]?.delivery_type}
          </FormHelperText>
          {/* Delivery Cost */}
          <Stack
            direction={"row"}
            gap={"20px"}
            width={"100%"}
            alignItems={"flex-start"}
            flexWrap={{ xs: "wrap", sm: "nowrap" }}
          >
            <Controller
              name={`aggregators.${index}.delivery_cost`}
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  label={
                    watch(`aggregators.${index}.delivery_type`) === "order"
                      ? t("orderCostIncludeTax")
                      : t("amountIncludeTax")
                  }
                  {...field}
                  error={!!errors.aggregators?.[index]?.delivery_cost}
                  helperText={
                    errors.aggregators?.[index]?.delivery_cost?.message
                  }
                  fullWidth
                  size="small"
                  sx={{ flexBasis: { xs: "100%", sm: "60%" } }}
                />
              )}
            />
            <Stack
              flexBasis={{ xs: "100%", sm: "40%" }}
              width={"100%"}
              bgcolor={"#FFF3EA"}
              borderRadius={"4px"}
              padding={1}
              direction={"row"}
              gap={"10px"}
            >
              <InfoOutlinedIcon color="primary" />
              <Typography>
                {watch(`aggregators.${index}.delivery_type`) === "order"
                  ? ` ${t("oneOrderCost")} ( ${formatNumber(
                      Number(watch(`aggregators.${index}.delivery_cost`))
                    )} )`
                  : generalT("deliveryCost")}
              </Typography>
            </Stack>
          </Stack>

          {/* Calculations */}
          <Divider variant="fullWidth" sx={{ width: "100%" }} />
          <Box width={"100%"}>
            <Box height={"7px"} bgcolor={"#D0D5DD"} />
            <Stack p={2} gap={1.5} bgcolor={"#F9FAFB"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {t("salesFromAgg")}
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {aggregatorSales ? formatNumber(aggregatorSales) : "--"}{" "}
                  {generalT("sar")}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {t("salesTax")}
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {aggregatorSales
                    ? formatNumber(
                        +(
                          (+aggregatorSales / journeyTaxDivider) *
                          journeyTaxPercentage
                        ).toFixed(2)
                      )
                    : "--"}{" "}
                  {generalT("sar")}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {generalT("aggFee")}:
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {watch(`aggregators.${index}.aggregator_fee`)
                    ? formatNumber(
                        +(
                          (+aggregatorSales / journeyTaxDivider) *
                          (+watch(`aggregators.${index}.aggregator_fee`) / 100)
                        ).toFixed(2)
                      )
                    : "--"}{" "}
                  {generalT("sar")}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {t("aggFeeTax")}
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {watch(`aggregators.${index}.aggregator_fee`)
                    ? formatNumber(
                        +(
                          (+aggregatorSales / journeyTaxDivider) *
                          (+watch(`aggregators.${index}.aggregator_fee`) /
                            100) *
                          journeyTaxPercentage
                        ).toFixed(2)
                      )
                    : "--"}{" "}
                  {generalT("sar")}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {generalT("orderQnty")}:
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {aggregatorOrderQuantity
                    ? formatNumber(aggregatorOrderQuantity)
                    : "--"}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {generalT("deliveryCost")}:
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {watch(`aggregators.${index}.delivery_type`) === "order"
                    ? formatNumber(
                        +(
                          +aggregatorOrderQuantity *
                          Number(
                            watch(`aggregators.${index}.delivery_cost`) ?? 0
                          )
                        ).toFixed(2)
                      )
                    : formatNumber(
                        +Number(
                          watch(`aggregators.${index}.delivery_cost`) ?? 0
                        ).toFixed(2)
                      )}{" "}
                  {generalT("sar")}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {t("deliveryCostTax")}
                </Typography>
                <Typography fontSize={"14px"} color={"#344054"}>
                  {watch(`aggregators.${index}.delivery_type`) === "order"
                    ? formatNumber(
                        +(
                          ((+aggregatorOrderQuantity *
                            Number(
                              watch(`aggregators.${index}.delivery_cost`)
                            ) || 0) /
                            journeyTaxDivider) *
                          journeyTaxPercentage
                        ).toFixed(2)
                      )
                    : formatNumber(
                        +(
                          (Number(
                            watch(`aggregators.${index}.delivery_cost`) ?? 0
                          ) /
                            journeyTaxDivider) *
                          journeyTaxPercentage
                        ).toFixed(2)
                      )}{" "}
                  {generalT("sar")}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </form>
    </div>
  );
};

export default AggregatorDetails;
