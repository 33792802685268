import { useMutation, useQuery } from "@tanstack/react-query";
import apiClient from "./apiClient";
import {
  IBranchesResponse,
  ICitiesResponse,
  IPaymentsResponse,
} from "src/types/generic";

export const useGetCities = () =>
  useQuery<any, any, ICitiesResponse>({
    queryKey: ["get-cities"],
    queryFn: async () => {
      return apiClient.get(`/api/cities`);
    },
    gcTime: 0,
  });

export const useGetBranches = () =>
  useQuery<any, any, IBranchesResponse>({
    queryKey: ["get-branches"],
    queryFn: async () => {
      return apiClient.get(`/api/branches`);
    },
    gcTime: 0,
  });

export const useGetPaymentMethods = (params?: {
  branches?: number[];
  page?: number;
  enabled?: boolean;
}) =>
  useQuery<any, any, IPaymentsResponse>({
    queryKey: ["get-payments-methods", params?.page, params?.branches],
    queryFn: async () => {
      return apiClient.get(`/api/paymentMethods`, { params: params || {} }); // If no params, it returns all payments
    },
    gcTime: 0,
    enabled: params?.enabled ?? true,
  });

export const useGetJourneys = (params: {
  branches: number[];
  enabled: boolean;
}) =>
  useQuery<any, any, any>({
    queryKey: ["get-journeys", params.branches.map((branch) => branch)],
    queryFn: async () => {
      return apiClient.get(`/api/journeys/get-journey-filter`, {
        params:
          params?.branches.length > 0 ? { branches: params?.branches } : {},
      }); // If no params, it returns all journeys
    },
    enabled: params?.enabled,
    gcTime: 0,
  });

export const useUpdateCompanyLockDate = () =>
  useMutation<void, any, { id: number | string; close_date: string }>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/companies/update-close-date/${id}`, rest);
    },
  });
