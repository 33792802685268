import { useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "./apiClient";

interface IParams {
  start_date: string;
  end_date: string;
  branches?: number[];
  enabled?: boolean;
}

export const useGetOCReport = ({ enabled, ...rest }: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-OC-3rd-eye-report", rest.start_date, rest.end_date],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/operation-cost-report`, {
        params: rest,
      });
    },
    enabled: enabled,
    gcTime: 0,
  });

export const useGetPaymentReport = ({ enabled, ...rest }: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-payment-3rd-eye-report", rest.start_date, rest.end_date],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/payment-report`, {
        params: rest,
      });
    },
    enabled: enabled,
    gcTime: 0,
  });

export const useGetInventoryReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-inventory-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/inventory-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetPurchaseReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-purchase-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/purchase-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetTaxReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-tax-3rd-eye-report", params.start_date, params.end_date],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/tax-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetForecastReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-forecast-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/forcast-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetAggregatorsReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-aggregators-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/aggregator-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetPLReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-p-l-3rd-eye-report", params.start_date, params.end_date],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/profit-and-loss-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

interface ISuppParams extends IParams {
  supplier_id: string;
}

export const useGetSupplierReport = (params: ISuppParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-supplier-3rd-eye-report",
      params.start_date,
      params.end_date,
      params.supplier_id,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/supplier-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

interface IAccountParams extends IParams {
  account_id: string;
}

export const useGetAccountReport = (params: IAccountParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-account-3rd-eye-report",
      params.start_date,
      params.end_date,
      params.account_id,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/account-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetItemsReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-items-3rd-eye-report", params.start_date, params.end_date],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/item-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetAggsStatementReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-aggs-statement-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/aggregator-statement-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetBalanceSheetReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-balance-sheet-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/balance-sheet-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

interface IPaymentTransParams extends IParams {
  payment_method_id: string;
}

export const useGetPaymentTransactionsReport = (params: IPaymentTransParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-payment-transactions-3rd-eye-report",
      params.start_date,
      params.end_date,
      params.payment_method_id,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/payment-movement-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

interface IPriceFluctuationParams extends IParams {
  item_id: string;
}

export const useGetItemsPriceFluctuationReport = (
  params: IPriceFluctuationParams
) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-items-price-fluctuation-3rd-eye-report",
      params.start_date,
      params.end_date,
      params.item_id,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/price-fluctuation-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });

export const useGetItemsFluctuationReport = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-items-fluctuation-3rd-eye-report",
      params.start_date,
      params.end_date,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/thirdEyes/item-fluctuation-report`, {
        params,
      });
    },
    enabled: false,
    gcTime: 0,
  });
