import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { NoData } from "src/shared/components/tables/no-data";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Box, Button, Stack, TablePagination, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FilterIcon from "src/assets/svg-icons/aggregators/filter.svg";
import DownloadIcon from "src/assets/svg-icons/download.svg";
import AddIcon from "@mui/icons-material/Add";
import AddEditItemPopup from "./add-edit-item";
import { useDownloadItems, useGetItemsList } from "src/api/purchasing/items";
import { LoadingButton } from "@mui/lab";
import { downloadFile, oneBranchHasPermission } from "src/utils";
import FilterDrawer from "./filters";
import ImportItemsPopup from "./import-items-popup";
import { InventoryPermissions } from "src/constants/permissions";

const AllTable = () => {
  const { t } = useTranslation("purchasing");
  const { t: inventoryT } = useTranslation("inventory");
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [name, setName] = useState("");
  const [sku, setSku] = useState("");
  const [type, setType] = useState("all");
  const [openImport, setOpenImport] = useState(false);

  const HeadText = [
    t("name"),
    t("SKU"),
    t("ingredientsUnit"),
    t("type"),
    t("shelfLife"),
    t("createdBy"),
    t("createdAt"),
    t("actions"),
  ];

  // APIS
  const { data, isFetching, refetch } = useGetItemsList({
    page: pageNumber + 1,
    ...(!!name && { name: name }),
    ...(!!sku && { sku: sku }),
    ...(!!type && type !== "all" && { type: type }),
  });
  const {
    data: downloadedData,
    refetch: refetchDownload,
    isFetching: isFetchingDownload,
    status,
  } = useDownloadItems({
    ...(!!name && { name: name }),
    ...(!!sku && { sku: sku }),
    ...(!!type && type !== "all" && { type: type }),
  });

  // permissions
  const addPermission = oneBranchHasPermission(InventoryPermissions.addItem);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (type: any) => {
    setOpen(true);
  };

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenImport = () => setOpenImport(true);
  const handleCloseImport = () => setOpenImport(false);

  const handleDownload = () => {
    refetchDownload();
  };

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    setPageNumber(0); // when filters change return to first page
    refetch();
  }, [name, sku, type]);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  useEffect(() => {
    if (status === "success" && !!downloadedData) {
      const utf8CSVData = `\uFEFF${downloadedData}`;
      downloadFile(utf8CSVData, "text/csv", "items");
    }
  }, [isFetchingDownload]);

  console.log({ type, name, sku });

  return (
    <Stack spacing={3} maxWidth={{ xs: "100%", md: `calc(100vw - 316px)` }}>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Stack
          direction={"row"}
          gap={2}
          flexWrap={"wrap"}
          mt={{ xs: 2, md: 0 }}
        >
          <Button
            color="tertiary"
            variant="outlined"
            endIcon={<img src={FilterIcon} height={"16px"} alt="" />}
            onClick={handleOpenFilter}
          >
            {t("filter")}
          </Button>
          <LoadingButton
            sx={{
              border: "1px solid #98A2B3",
              height: "40px",
              fontWeight: 500,
              borderRadius: "4px",
              padding: "7px 10px",
              background: "#FCFCFD",
              fontSize: "16px",
            }}
            color="tertiary"
            variant="outlined"
            endIcon={<img src={DownloadIcon} alt="" />}
            loading={isFetchingDownload}
            onClick={handleDownload}
          >
            {t("download")}
          </LoadingButton>
          {addPermission && (
            <>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpenImport}
              >
                {inventoryT("importItems")}
              </Button>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpen}
                size="small"
              >
                {t("addItem")}
              </Button>
            </>
          )}
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {HeadText.map((item) => (
                <TableCell
                  key={item}
                  align={"center"}
                  sx={{
                    bgcolor: "#F9FAFB",
                  }}
                >
                  <Typography
                    fontSize={"12px"}
                    color={"#475467"}
                    lineHeight={"18px"}
                    fontWeight={500}
                  >
                    {item}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isFetching || !data ? (
            <TableLoadingSkeleton rowsLength={15} cellsLength={8} />
          ) : (
            <TableBody>
              {data.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.sku}</TableCell>
                  <TableCell align="center">{row.unit?.name}</TableCell>
                  <TableCell align="center">
                    {t(row.type === "direct" ? "direct" : "nonDc")}
                  </TableCell>
                  <TableCell align="center">{row.shelf_life}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="right">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      {open && (
        <AddEditItemPopup
          open={open}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}

      <FilterDrawer
        open={openFilter}
        name={name}
        setName={setName}
        sku={sku}
        setSku={setSku}
        type={type}
        setType={setType}
        handleClose={handleCloseFilter}
      />

      {openImport && (
        <ImportItemsPopup
          open={openImport}
          handleClose={handleCloseImport}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};
export default AllTable;
