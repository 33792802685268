import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  Control,
  FieldErrors,
  UseFormWatch,
  UseFormSetValue,
  Controller,
} from "react-hook-form";
import InfoBox from "src/shared/components/info-box";
import { FormModeTypes, IPaymentData } from "src/types/generic";
import { useTranslation } from "react-i18next";
import { FormData } from "../../../constants";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "src/assets/svg-icons/aggregators/delete.svg";
import AddMoreIcon from "src/assets/svg-icons/settings/add_more.svg";

interface IProps {
  control: Control<FormData, any>;
  errors: FieldErrors<FormData>;
  watch: UseFormWatch<FormData>;
  setValue: UseFormSetValue<FormData>;
  mode: FormModeTypes;
  otherRevenueFields: any;
  appendOtherRevenue: any;
  removeOtherRevenue: any;
  paymentsList: IPaymentData[];
}

const OtherSales: React.FC<IProps> = ({
  control,
  errors,
  watch,
  setValue,
  mode,
  otherRevenueFields,
  appendOtherRevenue,
  removeOtherRevenue,
  paymentsList,
}) => {
  const { t } = useTranslation("addSales");
  const { t: generalT } = useTranslation("general");
  const { t: purchasingT } = useTranslation("purchasing");

  const handleAppendPayment = () => {
    appendOtherRevenue({
      payment_method_id: "",
      amount: "",
    });
  };

  const handleRemoveOtherRevenuePayment = (index: number) => {
    removeOtherRevenue(index);
  };

  const onClickYesRevenueOption = () => {
    if (otherRevenueFields.length === 0) {
      appendOtherRevenue({ payment_method_id: "", amount: "" });
    }
  };

  const onClickNoRevenueOption = () => {
    removeOtherRevenue();
  };

  useEffect(() => {
    if (watch("include_other_revenue_tax") === "1") {
      setValue(
        "other_revenue_tax",
        `${(((Number(watch("other_revenue")) || 0) / 1.15) * 0.15).toFixed(2)}`
      );
    } else if (watch("include_other_revenue_tax") === "0") {
      setValue(
        "other_revenue_tax",
        `${((Number(watch("other_revenue")) || 0) * 0.15).toFixed(2)}`
      );
    }
  }, [watch("include_other_revenue_tax"), watch("other_revenue")]);

  return (
    <Stack gap={2}>
      <Controller
        name="discount"
        control={control}
        render={({ field }) => (
          <TextField
            type="number"
            label={t("discountOptional")}
            {...field}
            error={!!errors.discount}
            helperText={errors.discount?.message}
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: field.value !== undefined,
            }}
          />
        )}
      />
      <Controller
        name="tips"
        control={control}
        render={({ field }) => (
          <TextField
            type="number"
            label={t("tipsOptional")}
            {...field}
            error={!!errors.tips}
            helperText={errors.tips?.message}
            fullWidth
            size="small"
            InputLabelProps={{
              shrink: field.value !== undefined,
            }}
          />
        )}
      />
      {/* other revenue */}
      <Stack
        direction={"row"}
        alignItems={"center"}
        gap={{ xs: 1, sm: 1, md: 3 }}
        flexWrap={"wrap"}
        width={"100%"}
      >
        <Typography color={"textSecondary"}>
          {t("doYouHaveOtherRevenue")}
        </Typography>
        <FormControl
          fullWidth
          size="small"
          error={!!errors.hasOtherRevenue}
          sx={{ width: "fit-content", ml: { xs: "auto", sm: "unset" } }}
        >
          <Controller
            name="hasOtherRevenue"
            control={control}
            render={({ field }) => (
              <RadioGroup
                {...field}
                key={field.value}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label={generalT("yes")}
                  sx={{ color: "#98A2B3" }}
                  onClick={onClickYesRevenueOption}
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      icon={<CircleOutlinedIcon />}
                      checkedIcon={<CheckCircleIcon />}
                    />
                  }
                  label={generalT("no")}
                  sx={{ color: "#98A2B3" }}
                  onClick={onClickNoRevenueOption}
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Stack>

      {watch("hasOtherRevenue") === "yes" ? (
        <>
          {/* other revenue amount */}
          <Stack direction={"row"} alignItems={"flex-start"} gap={1}>
            <Box sx={{ width: 260 }}>
              <Controller
                name="other_revenue"
                control={control}
                render={({ field }) => (
                  <TextField
                    type="number"
                    fullWidth
                    label={t("otherRevenue")}
                    size="small"
                    {...field}
                    error={!!errors.other_revenue}
                    helperText={errors.other_revenue?.message}
                    InputLabelProps={{
                      shrink: field.value !== undefined,
                    }}
                  />
                )}
              />
            </Box>
            <FormControl
              fullWidth
              size="small"
              error={!!errors.include_other_revenue_tax}
              sx={{
                width: "fit-content",
                ml: { xs: "auto", sm: "unset" },
              }}
            >
              <Controller
                name="include_other_revenue_tax"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    key={field.value}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          size="small"
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={generalT("includeTax")}
                      sx={{ color: "#98A2B3" }}
                    />
                    <FormControlLabel
                      value="0"
                      control={
                        <Radio
                          size="small"
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label={generalT("excludeTax")}
                      sx={{ color: "#98A2B3" }}
                    />
                  </RadioGroup>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.include_other_revenue_tax?.message}
              </FormHelperText>
            </FormControl>
          </Stack>
          {/* tax Optional */}
          <Controller
            name="other_revenue_tax"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                label={purchasingT("taxOptional")}
                fullWidth
                size="small"
                {...field}
                error={!!errors.other_revenue_tax}
                helperText={errors.other_revenue_tax?.message}
                InputLabelProps={{
                  shrink: field.value !== undefined,
                }}
              />
            )}
          />
          {/* payments */}
          {otherRevenueFields.map((paymentField: any, index: number) => {
            return (
              <Stack
                key={paymentField.id}
                direction={"row"}
                gap={1}
                width={"100%"}
              >
                <FormControl
                  fullWidth
                  size="small"
                  error={
                    errors.otherRevenuePayments &&
                    !!errors.otherRevenuePayments[index]?.payment_method_id
                  }
                >
                  <InputLabel id="demo-simple-select-label">
                    {generalT("paymentMethod")}
                  </InputLabel>
                  <Controller
                    name={`otherRevenuePayments.${index}.payment_method_id`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        key={field.value}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label={generalT("paymentMethod")}
                      >
                        {paymentsList
                          ?.filter((pay) => pay.status)
                          .map(({ id, name }) => {
                            return (
                              <MenuItem key={id} value={`${id}`}>
                                {name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  />
                  <FormHelperText id="my-helper-text">
                    {
                      errors.otherRevenuePayments?.[index]?.payment_method_id
                        ?.message
                    }
                  </FormHelperText>
                </FormControl>

                <Controller
                  name={`otherRevenuePayments.${index}.amount`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      label={purchasingT("amount")}
                      variant="outlined"
                      {...field}
                      error={!!errors.otherRevenuePayments?.[index]?.amount}
                      helperText={
                        errors.otherRevenuePayments?.[index]?.amount?.message
                      }
                      fullWidth
                      size="small"
                      InputProps={{
                        inputProps: { min: 0 },
                      }}
                    />
                  )}
                />

                {mode === "add" && (
                  <IconButton
                    onClick={() => handleRemoveOtherRevenuePayment(index)}
                  >
                    <img src={DeleteIcon} alt="" />
                  </IconButton>
                )}
              </Stack>
            );
          })}
          {watch("hasOtherRevenue") === "yes" && (
            <Button
              variant="text"
              size="small"
              startIcon={<img src={AddMoreIcon} alt="" />}
              onClick={handleAppendPayment}
              sx={{ alignSelf: "flex-start" }}
            >
              {purchasingT("addAnotherMethod")}
            </Button>
          )}
        </>
      ) : null}

      <InfoBox variant="info" text={t("otherRevenueDescription")} />
    </Stack>
  );
};

export default OtherSales;
