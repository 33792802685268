import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableActions from "./table-actions";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import {
  Box,
  Button,
  Stack,
  Switch,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import {
  useGetOCAccountsList,
  useUpdateOCAccountMutation,
} from "src/api/operation-cost/accounts";
import AddIcon from "@mui/icons-material/Add";
import AddEditAccountPopup from "../add-new-account";
import { useTranslation } from "react-i18next";
import { oneBranchHasPermission } from "src/utils";
import { OCPermissions } from "src/constants/permissions";

const AccountsTable = () => {
  const { t } = useTranslation("operationCost");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    generalT("accountName"),
    t("type"),
    generalT("balance"),
    generalT("createdBy"),
    generalT("createdAt"),
    generalT("activeInactive"),
    generalT("actions"),
  ];

  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [open, setOpen] = useState(false);

  //  permissions
  const addOCAccountPermission = oneBranchHasPermission(OCPermissions.addOCAccount);
  const changeStatusPermission = oneBranchHasPermission(
    OCPermissions.changeOCAccountStatus
  );

  const { data, isLoading, refetch } = useGetOCAccountsList({
    page: pageNumber + 1,
  });
  const { mutate, status, error } = useUpdateOCAccountMutation();
  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!!data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  //Update branch success and error handling
  useEffect(() => {
    if (status === "success") {
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  return (
    <Box>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={2}
      >
        <Typography
          fontWeight={600}
          lineHeight={"19.2px"}
          color={"#475467"}
          sx={{ mb: { xs: "12px", md: 0 }, textTransform: "uppercase" }}
        >
          {t("accountsList")}
        </Typography>
        {addOCAccountPermission && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpen}
          >
            {t("addNewAccount")}
          </Button>
        )}
      </Stack>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={7} />
          ) : (
            <TableBody>
              {data?.data.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell>
                    {row.type === "variable"
                      ? t("variable")
                      : row.type === "asset"
                      ? t("asset")
                      : t("fixedCost")}
                  </TableCell>
                  <TableCell align="center">{row.balance.toFixed(2)}</TableCell>
                  <TableCell align="center">{row.created_by?.name}</TableCell>
                  <TableCell align="center" dir="ltr">
                    {row.created_at}
                  </TableCell>
                  <TableCell align="center">
                    <Switch
                      defaultChecked={row.status}
                      onChange={(e) => {
                        mutate({ id: row.id, status: e.target.checked });
                      }}
                      disabled={!changeStatusPermission}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableActions row={row} refetch={refetch} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 2 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />

      <AddEditAccountPopup
        open={open}
        handleClose={handleClose}
        refetch={refetch}
      />
    </Box>
  );
};
export default AccountsTable;
