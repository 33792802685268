import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LineChart from "./chart";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useGetItemsList } from "src/api/purchasing/items";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import correctIcon from "src/assets/svg-icons/correct.svg";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ItemsChart = ({
  data,
  items,
  setItems,
  isFetching,
  currentQuarter,
  prevQuarter,
}: {
  data: any;
  items: number[];
  setItems: Function;
  isFetching: boolean;
  currentQuarter: string | number;
  prevQuarter: string | number;
}) => {
  const { t } = useTranslation("purchasing");
  const [tempSelectedItems, setTempSelectedItems] = useState<number[]>(items);

  // APIS
  const { data: itemsData } = useGetItemsList();

  const handleClose = () => {
    setItems(tempSelectedItems); // Set the final state
  };

  const handleChangeItems = (value: any[], reason: string) => {
    console.log({ reason });
    setTempSelectedItems(value);

    if (reason === "clear") {
      setItems([]); // Clear the input value
    }

    if (reason === "removeOption") {
      setTempSelectedItems(value);
      setItems(value); // Clear the input value
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: "8px",
        border: "1px solid #EAECF0",
      }}
    >
      {/* <Equations data={data} /> */}
      <Box px={3} pt={3} pb={0.5}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography
            fontSize={"16px"}
            lineHeight={"22px"}
            textTransform={"capitalize"}
            fontWeight={500}
          >
            {t("items")}
          </Typography>

          {/* Select Items */}
          <Box sx={{ minWidth: 180, maxWidth: 450 }}>
            <Autocomplete
              fullWidth
              size="small"
              limitTags={4}
              multiple
              id="tags-outlined"
              options={itemsData?.data || []}
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.name}
              renderOption={(props, option, { selected }) => (
                <li key={`${props.id}_${option.id}`} {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography>{option.name}</Typography>
                </li>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label={t("items")}
                  placeholder={t("item")}
                />
              )}
              onChange={(event, value, reason) => {
                console.log({ event });
                handleChangeItems(value, reason);
              }}
              renderTags={(selected, getTagProps) =>
                selected.map((option: any, index: number) => (
                  <Chip
                    size="small"
                    label={option.name}
                    {...getTagProps({ index })}
                    key={option.id}
                    onDelete={undefined} // Disable the "x" delete icon
                  />
                ))
              }
              onClose={handleClose}
            />
          </Box>
        </Box>
        {isFetching ? (
          <Stack gap={3} py={2}>
            <Skeleton variant="rectangular" width={"100%"} height={35} />
            <Skeleton variant="rectangular" width={"100%"} height={35} />
            <Skeleton variant="rectangular" width={"100%"} height={35} />
            <Skeleton variant="rectangular" width={"100%"} height={35} />
            <Skeleton variant="rectangular" width={"100%"} height={35} />
            <Skeleton variant="rectangular" width={"100%"} height={35} />
          </Stack>
        ) : (
          <Box>
            <Stack direction={"row"} gap={2}>
              {/* this quarter */}
              <Stack
                p={{ xs: "5px", sm: "10px" }}
                direction={"row"}
                gap={"8px"}
              >
                <img src={correctIcon} alt="" />

                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                      width: "max-content",
                    }}
                    lineHeight={"19.2px"}
                    color={"#475467"}
                    fontWeight={600}
                  >
                    {t("thisQuarter")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      width: "max-content",
                    }}
                    fontWeight={700}
                    lineHeight={"15.6px"}
                    // color={""}
                  >
                    {currentQuarter}%
                  </Typography>
                </Stack>
              </Stack>
              {/* prev quarter */}
              <Stack
                p={{ xs: "5px", sm: "10px" }}
                direction={"row"}
                gap={"8px"}
              >
                <img src={correctIcon} alt="" />

                <Stack>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "16px" },
                      width: "max-content",
                    }}
                    lineHeight={"19.2px"}
                    color={"#475467"}
                    fontWeight={600}
                  >
                    {t("prevQuarter")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      width: "max-content",
                    }}
                    fontWeight={700}
                    lineHeight={"15.6px"}
                    // color={""}
                  >
                    {prevQuarter}%
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <LineChart data={data} />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
export default ItemsChart;
