import { useMutation, useQuery } from "@tanstack/react-query";
import { APIErrorResponse } from "src/types/generic";
import apiClient from "../apiClient";

interface IParams {
  page?: number;
  start_date?: string;
  end_date?: string;
  reference_number?: string;
  type?: string;
  payment_method_id?: string;
  supplier_id?: string;
  account_id?: string;
  has_government_tax?: number;
}

interface IEventBody {
  id?: number;
  name: string;
  email?: string;
  phone?: string;
  delivery_days?: number;
  start_balance?: number;
  bank_account?: string;
}

export const useGetPurchasingEventsList = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: [
      "get-purchasing-events-list",
      params.page,
      params.start_date,
      params.end_date,
      params.reference_number,
      params.type,
      params.account_id,
      params.payment_method_id,
      params.supplier_id,
      params.has_government_tax,
    ],
    queryFn: async () => {
      return apiClient.get(`/api/purchaseEvents`, { params });
    },
  });

export const useAddEventMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchaseEvents`, data);
    },
  });

export const useUpdateEventMutation = () =>
  useMutation<void, APIErrorResponse, IEventBody>({
    mutationFn: async (data) => {
      const { id, ...rest } = data;
      return apiClient.put(`/api/purchaseEvents/${id}`, rest);
    },
  });

export const useDeleteEventMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/purchaseEvents/${id}`);
    },
  });

// Event files
export const useGetEventFiles = (id: number) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["get-purchase-event-files", id],
    queryFn: async () => {
      return apiClient.get(`/api/purchaseEventFiles?purchase_event_id=${id}`);
    },
    gcTime: 0,
  });

export const useAddEventFileMutation = () =>
  useMutation<void, APIErrorResponse, FormData>({
    mutationFn: async (data) => {
      return apiClient.post(`/api/purchaseEventFiles`, data);
    },
  });

export const useDeleteEventFileMutation = () =>
  useMutation<void, APIErrorResponse, number>({
    mutationFn: async (id) => {
      return apiClient.delete(`/api/purchaseEventFiles/${id}`);
    },
  });

export const useDownloadPurchasingEvents = (params: IParams) =>
  useQuery<any, APIErrorResponse, any>({
    queryKey: ["download-purchasing-events-list"],
    queryFn: async () => {
      return apiClient.get(`/api/purchaseEvents/download`, { params });
    },
    enabled: false,
    gcTime: 0,
  });
